import React, { useState ,useEffect} from 'react'
import { Link,useNavigate,useParams } from 'react-router-dom'
import {blockWordList,blockWordUpdateStatus,deleteBlockWord,blockWordApproveReject} from '../../api/apiHandler'
//Datatable Modules
import 'jquery/dist/jquery.min.js'
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import $ from 'jquery'; 
import Heading from '../../components/Heading/Heading';
import Swal from 'sweetalert2'
import { renderToStaticMarkup } from 'react-dom/server'

export default function BlockWordListing(props) {

    const [myBlockWordList, setBlockWordList] = useState(null)

    useEffect(() => {
        let mounted = true;
        props.isLoader(true)
        blockWordList({"page":"1","limit":"1000","sort_by":"id","order_by":"desc"}).then((resposnse)=>{
            console.log(resposnse)
            if(resposnse.data.code==1){
                setBlockWordList(resposnse.data.data.list);
            }else{
                setBlockWordList([]);
            }
        })
        return () => mounted = false;
    }, []);


    $(document).ready(function () {
        $('#blockWords').DataTable(
            {
                pagingType: 'full_numbers',
                  pageLength: 10,
                  order: [[0, 'desc']],
                  processing: true,
                  dom: 'Bfrtip',
                      buttons: ['copy', 'csv', 'print'
                      ],
                      "bDestroy": true
            }
        );
    });

    let navigate = useNavigate(); 
    
    const redirectCreateBlock = ()=>{
        let path ="/createBlockWord"
        navigate(path)
    }

    const statusChangeListner = (position)=>{

        console.log(position) 
 
        props.isLoader(true)
 
        console.log("Call api change status")
 
        let apiData={}
        if(myBlockWordList[position].is_active===1){
             apiData={"blocked_word_id":myBlockWordList[position].id,"status":0}        
        }
        else{
             apiData={"blocked_word_id":myBlockWordList[position].id,"status":1}        
        }
 
        console.log(apiData)
 
        blockWordUpdateStatus(apiData).then((resposnse)=>{
         console.log(resposnse)
         props.isLoader(false)
         if(resposnse.data.code==1){
             callAfterApiChangeStatus(position,true)
         }else{
             callAfterApiChangeStatus(position,false)
         }
         })    
     }
 
     const callAfterApiChangeStatus = (position,status)=>{
 
         if(status){
 
             if(myBlockWordList[position].is_active===1){
                myBlockWordList[position].is_active=0       
                }else{
                    myBlockWordList[position].is_active=1
                 }
         }
 
         setBlockWordList(myBlockWordList)
 
         manageCheckStatus(position)
 
     }
 
     const manageCheckStatus = (position)=>{
        var item=myBlockWordList[position];
 
        console.log(position) 
  
         var data =renderToStaticMarkup(renderStatusUiText(myBlockWordList[position]));
         $('#blockWords').DataTable().row("#blockWordId"+item.id).cell("#changeStatus"+item.id).data(data).draw(false);
  
     }
 
     const renderStatusUiText=(item)=>{
         return (
             item.is_active===1 ?
             <>
             <span class="badge badge-success">Active</span>
             </>
             :
             <>                                                
             <span class="badge badge-danger">Inactive</span>
             </>
 
         )
     }

     function deleteBlockIdWise(id){
        console.log("Call Delete"+id)
        if(window.confirm("Are you sure you want to delete?")){
            props.isLoader(true)
            deleteBlockWord({"blocked_word_id":id}).then((resposnse)=>{
                console.log(resposnse);
                if(resposnse.data.code==1){
                    $('#blockWords').DataTable().row("#blockWordId"+id).remove().draw();
                }else{
                }
                props.isLoader(false)
            });
        }
    }

    const approveRejectBlockWord =(id)=>{
        Swal.fire({
            title: 'Do you want to perform action on block word?',
            icon: 'question',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Approve',
            denyButtonText: `Reject`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              apiCallApproveReject(id,"Approved")
            } else if (result.isDenied) {
              apiCallApproveReject(id,"Rejected")
            }
          })
    }

    const apiCallApproveReject =(id,statusChange)=>{
        props.isLoader(true)
        var apiData={"blocked_word_id":id,"status":statusChange}        
        blockWordApproveReject(apiData).then((resposnse)=>{
            console.log(resposnse)
            props.isLoader(false)
            navigate(0)
            }) 
    }

 

    if (myBlockWordList==null) return null;
    props.isLoader(false)

    return (
    <section className="content home">
        <Heading title="Block Word"/>
        <div className="container-fluid"> 
        <div className="row clearfix">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card">
                    <div className="header">
                    <button type="button" className="btn btn-raised btn-primary m-t-15 m-b-15 waves-effect right" onClick={redirectCreateBlock}>Create</button>
                    </div>
                    <div className="body">
                        <table id="blockWords" className="table table-bordered table-striped table-hover dataTable js-exportable">
                            <thead>
                                <tr>
                                    <th>Created Date</th>
                                    <th>Id</th>
                                    <th>Word</th>
                                    <th>Created By Id</th>
                                    <th>Created By</th>
                                    <th>Role</th>
                                    <th>Status</th>
                                    <th>Status Action</th>
                                    <th>Staus Active</th>
                                    <th>Staus Active Action</th>
                                    <th>Action</th> 
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                <th>Created Date</th>
                                    <th>Id</th>
                                    <th>Word</th>
                                    <th>Created By Id</th>
                                    <th>Created By</th>
                                    <th>Role</th>
                                    <th>Status</th>
                                    <th>Status Action</th>
                                    <th>Staus Active</th>
                                    <th>Staus Active Action</th>
                                    <th>Action</th> 
                                 </tr>
                            </tfoot>
                            <tbody>

                            { 
                            myBlockWordList && myBlockWordList.map((item,index)=>{
                                    return(
                                        <tr id={"blockWordId"+item.id}>
                                            <td>{item.created_at}</td>
                                            <td>{item.id}</td>
                                            <td>{item.word}</td>
                                            <td>{item.created_by_id}</td>
                                            <td>{item.created_by_name}</td>
                                            <td>{item.created_by}</td>
                                            <td>{item.status}</td>
                                            <td className="text-center">
                                                { 
                                                (item.status === "Pending") 
                                                ?
                                                <a onClick={()=> {approveRejectBlockWord(item.id)}} className="btn btn-default col-blue"><i className="zmdi zmdi-edit"></i></a>
                                                :
                                                (item.status === "Approved") 
                                                ?
                                                <i class="zmdi zmdi-check col-green"></i>
                                                :
                                                <i class="zmdi zmdi-close col-red"></i>
                                             }
                                            </td>
                                            <td id={"changeStatus"+item.id} className="text-center" > 
                                             {
                                                item.is_active===1 ?
                                                <>
                                                <span class="badge badge-success">Active</span>
                                                </>
                                                :
                                                <>                                                
                                                <span class="badge badge-danger">Inactive</span>
                                                </>
                                             }
                                            </td>
                                            <td>
                                            <i onClick={()=>statusChangeListner(index)} className="zmdi zmdi-refresh-alt"> </i>
                                            </td>
                                            <td className="text-center">
                                            <div className='action-btn'>                        
                                                <a onClick={()=> {deleteBlockIdWise(item.id)}} className="btn btn-default col-red"><i className="zmdi zmdi-delete"></i></a>
                                            </div>    
                                            </td>
                                        </tr>
                                        )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </section>
    );
}
