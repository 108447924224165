import React, { useState,useEffect } from 'react'
import { useParams ,useNavigate} from 'react-router-dom'
import {createAvatar,updateAvatar,getAvatarDetails,upload_file,fileUploadApiCall} from '../../api/apiHandler'
import {uploadAvatarImages,getMainBucketPath,getAvatarBucketPath} from '../../AWS/MyS3'
import $ from 'jquery';
import Select from 'react-select';


export default function CreateAvatar(props) {
    let id =  useParams()
    let navigate = useNavigate(); 

    const typeData =  [
        { value: 'student', label: 'Student' },
        { value: 'teacher', label: 'Teacher' }
      ] 

    window.Buffer = window.Buffer || require("buffer").Buffer;

    const [selectedAvatarFile, setAvatarFile] = useState(null);
    const [selectedTypeData, setSelectedTypeData] = useState(typeData[0]);

    const handleTypeChange=(data)=>{
        console.log(data)
        setSelectedTypeData(data)
    }

    const handleFileAvatarInput = (e) => {
        props.isLoader(true);
        const file = e.target.files[0];
        const newFileName = new Date().getTime() + file.name;
        const content_type = file.type;
        const key = `cybersmarties/avatar/${newFileName}`;
        upload_file({key, content_type}).then(data => {
            if (data.data.code === 1) {
                const signedUrl = data.data.data ? data.data.data : null;
                const fileParameters = {
                    signedUrl: signedUrl,
                    file: file,
                    contentType: content_type,
                    onProgress: null
                }
                fileUploadApiCall(fileParameters).then(resultdata => {
                    setAvatarFile(newFileName);
                    props.isLoader(false);
                }).catch(err => {
                    props.isLoader(false);
                    console.log('handleFileProfileInput', err);
                });
            }
        }).catch(err => {
            props.isLoader(false);
            console.log(err);
        });
    }

    const clickSubmitButton = (e)=>{
        e.preventDefault();

    if(props.type=="e"){
        updateApiCall()
    }else{
        createApiCall()
    }

    }

    const createApiCall = () =>{
        console.log("Create Api call")
        props.isLoader(true)
        let userData = JSON.parse(localStorage.getItem("userData"));
        let apiData={"name":selectedAvatarFile,"type":selectedTypeData.value,"user_id":userData.id,"user_role":userData.role,"user_name":userData.username}

        console.log(apiData)

        createAvatar(apiData).then((resposnse)=>{
            props.isLoader(false)
            console.log(resposnse)
            if(resposnse.data.code==1){
                navigate(-1)
            }
        });     
    }

    const updateApiCall = () =>{
        console.log("Edit Api call")
        props.isLoader(true)
        let apiData={"avatar_id":id.avatarId,"name":selectedAvatarFile,"type":selectedTypeData.value}

        console.log(apiData)

        updateAvatar(apiData).then((resposnse)=>{
            props.isLoader(false)
            console.log(resposnse)
            if(resposnse.data.code==1){
                navigate(-1)
            }
        });     
    } 

    useEffect(() => {
        let mounted = true;

        if(props.type=="e"){
            console.log("Edit")
            console.log(id)
            props.isLoader(true)
            getAvatarDetails({"avatar_id":id.avatarId}).then((resposnse)=>{
                props.isLoader(false)
                console.log(resposnse)
                if(resposnse.data.code==1){
                    
                  //  let avatarImagePath=resposnse.data.data.name.replace(getMainBucketPath(),"").replace(getAvatarBucketPath(),"")
                  
                    let avatarImagePath=resposnse.data.data.name

                    $('#avatarImage').next(".custom-file-label").html(avatarImagePath);

                    if(!(avatarImagePath ==="")){
                        $('#avatarImage').prop('required',false);
                    }

                    setAvatarFile(avatarImagePath)

                    let selectData =typeData.filter((item)=> {return item.value === resposnse.data.data.type})
                    setSelectedTypeData(selectData[0])
                    

                }else{
                    navigate(-1)
                }
            });                   
        }

        return () => mounted = false;
    }, []);

    $(document).ready(function() {
        $('input[type="file"]').on("change", function() {
          let filenames = [];
          let files = this.files;
          if (files.length > 1) {
            filenames.push("Total Files (" + files.length + ")");
          } else {
            for (let i in files) {
              if (files.hasOwnProperty(i)) {
                filenames.push(files[i].name);
              }
            }
          }
          $(this)
            .next(".custom-file-label")
            .html(filenames.join(","));
        });
      });

  return (
    <section className="content">

    <div className="container-fluid"> 
        <div className="row clearfix ">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card m-t-15">
                    <div className="header">
                        <h2>{ (props.type == "e") ? "Edit Avatar" : "Create Avatar"}</h2>
                    </div>
                    <div className="body">
                    <form id="form_validation" method="" onSubmit={clickSubmitButton} action="#">
                        <label htmlFor="school_log">Upload Avatar</label>
                            <div className="form-group">
                                <div className="custom-file">
                                    <input type="file" name="files[]" className="custom-file-input form-control" id="avatarImage" required onChange={handleFileAvatarInput} accept="image/*"/>
                                    <label className="custom-file-label" htmlFor="customFile">Choose file</label>
                                </div>
                            </div>

                            <label htmlFor="name_en">Select Type</label>
                                    <div className="form-group">
                                    <Select
                                                    value={selectedTypeData}
                                                    onChange={handleTypeChange}
                                                    options={typeData}
                                                    name="selectTypeData" 
                                                 />
                                      <input name="typeSelect" className='dropHideInput' value={ (selectedTypeData == null)? "" : ""+selectedTypeData.value  } id="typeSelect" type="text" required/>            
                                    </div>
                            <button className="btn btn-raised btn-primary waves-effect" type="submit">SUBMIT</button>
                        </form>                     
                    </div>
                </div>
            </div>
        </div>
    </div>
    </section>
  )
}
