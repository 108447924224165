import React, {useEffect, useState, useCallback} from 'react'
import {useParams, useNavigate} from "react-router-dom";
import {createFAQ,editFAQ,getFAQDetails} from '../../api/apiHandler'

export default function FAQCreate(props) {

   window.Buffer = window.Buffer || require("buffer").Buffer;
    let navigate = useNavigate();

    const [FAQDetails, setFAQDetails] = useState(null)

    let id = useParams()

    const initalValue = {en_question: "",ar_question:"",tu_question:"", en_answer: "", ar_answer: "", tu_answer: ""}
    const [formValues, setFormValues] = useState(initalValue)

    const handleChange = (e) => {
        if (e.target.value.trim() == "") {
            e.target.value = e.target.value.trim()
        }
        const {name, value} = e.target;
        setFormValues({...formValues, [name]: value});
    }

    const clickSubmitButton = (e) => {
        e.preventDefault();
        console.log(formValues)
        if (props.type == "e") {
            updateApiCall(formValues)
        } else {
            createApiCall(formValues)
        }
    }

    const createApiCall = (initValues) => {
        console.log("Create Api Call")
        props.isLoader(true)

        

        
        let apiData={"en_question":initValues.en_question,"ar_question":initValues.ar_question,"tu_question":initValues.tu_question,"en_answer":initValues.en_answer,"ar_answer":initValues.ar_answer,"tu_answer":initValues.tu_answer}

        console.log(apiData)

        createFAQ(apiData).then((resposnse)=>{
            props.isLoader(false)
            console.log(resposnse)
            if(resposnse.data.code==1){
                navigate(-1)
            }
        });        
    }

    const updateApiCall = (initValues) => {
        console.log("Update Api Call")
        props.isLoader(true)


        let apiData={"faqs_id":id.faqId,"en_question":initValues.en_question,"ar_question":initValues.ar_question,"tu_question":initValues.tu_question,"en_answer":initValues.en_answer,"ar_answer":initValues.ar_answer,"tu_answer":initValues.tu_answer}

        console.log(apiData)

        editFAQ(apiData).then((resposnse)=>{
            props.isLoader(false)
            console.log(resposnse)
            if(resposnse.data.code==1){
                navigate(-1)
            }
        });
    }

    useEffect(() => {
        if (props.type == "e") {
            console.log("Edit")
            props.isLoader(true)
            getFAQDetails({"faqs_id":id.faqId}).then((resposnse)=>{
                console.log(resposnse)
                if(resposnse.data.code==1){

                    let data= {en_question: resposnse.data.data.en_question,ar_question: resposnse.data.data.ar_question,tu_question: resposnse.data.data.tu_question,en_answer: resposnse.data.data.en_answer,ar_answer: resposnse.data.data.ar_answer,tu_answer: resposnse.data.data.tu_answer} 
                    setFormValues(data);
                    setFAQDetails(resposnse.data.data)
                    props.isLoader(false)
                   
                }else{
                    navigate(-1)
                }
          })   
        }
    }, []);

    return (
        <section className="content">
            <div className="container-fluid">
                <div className="row clearfix ">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="card m-t-15">
                            <div className="header">
                                <h2>{(props.type == "e") ? "Edit FAQ" : "Create FAQ"}</h2>
                            </div>
                            <div className="body">
                                <form id="form_validation" method="" onSubmit={clickSubmitButton} action="#">
                                    <label htmlFor="title">Question English</label>
                                    <div className="form-group">
                                        <div className="form-line">
                                            <input type="text" placeholder='Question English' className="form-control" id="en_question"
                                                   name="en_question" required value={formValues.en_question}
                                                   onChange={handleChange}/>
                                        </div>
                                    </div>

                                    <label htmlFor="description">Answer English</label>
                                    <div className="form-group">
                                        <div className="form-line">
                                            <textarea placeholder='Answer English' cols="30" rows="5"
                                                      className="form-control no-resize" id="en_answer" name="en_answer"
                                                      required value={formValues.en_answer}
                                                      onChange={handleChange}></textarea>
                                        </div>
                                    </div>

                                    <label htmlFor="ar_title">Question Arabic</label>
                                    <div className="form-group">
                                        <div className="form-line">
                                            <input type="text" placeholder='Question Arabic' className="form-control" id="ar_question"
                                                   name="ar_question" required value={formValues.ar_question}
                                                   onChange={handleChange}/>
                                        </div>
                                    </div>

                                    <label htmlFor="ar_description">Answer Arabic</label>
                                    <div className="form-group">
                                        <div className="form-line">
                                            <textarea placeholder='Answer Arabic' cols="30" rows="5"
                                                      className="form-control no-resize" id="ar_answer" name="ar_answer"
                                                      required value={formValues.ar_answer}
                                                      onChange={handleChange}></textarea>
                                        </div>
                                    </div>

                                    <label htmlFor="tu_title">Question Turkish</label>
                                    <div className="form-group">
                                        <div className="form-line">
                                            <input type="text" placeholder='Question Turkish' className="form-control" id="tu_question"
                                                   name="tu_question" required value={formValues.tu_question}
                                                   onChange={handleChange}/>
                                        </div>
                                    </div>

                                    <label htmlFor="tu_description">Answer Turkish</label>
                                    <div className="form-group">
                                        <div className="form-line">
                                            <textarea placeholder='Answer Turkish' cols="30" rows="5"
                                                      className="form-control no-resize" id="tu_answer" name="tu_answer"
                                                      required value={formValues.tu_answer}
                                                      onChange={handleChange}></textarea>
                                        </div>
                                    </div>
                                    <button className="btn btn-raised btn-primary waves-effect" type="submit">SUBMIT</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}