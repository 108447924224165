import {
    getClassList,
    getUserList,
    createDevision,
} from "../../api/apiHandler";
import { divisionColumnMapping } from "../Common/ColumnMappings";

const createBulkDivision = ({
    schoolId,
    updateFeedback,
    data,
    setAddedDataList,
    resetData,
}) => {
    console.log(schoolId);
    updateFeedback("Started executing process");
    updateFeedback("Mapping column names");

    const columnNamesMap = divisionColumnMapping(Object.keys(data[0]));

    let breakExecution = false;

    console.log(columnNamesMap);
    columnNamesMap.forEach((value, columnName) => {
        if (!value) {
            updateFeedback(`Unknown column name: ${columnName}`, "error");
            breakExecution = true;
        }
    });

    if (breakExecution) return;

    updateFeedback("Starting API calls");
    data.forEach((teacher, index) => {
        let payload = {};
        columnNamesMap.forEach((value, columnName) => {
            payload[value] = teacher[columnName];
        });

        payload = {
            ...payload,
            logo_image: "1655984251612school-profile.png",
            cover_image: "1655984339746highschool.jpeg",
        };

        updateFeedback(
            `Adding division: ${payload.class_name} - ${payload.name}`
        );
        console.log("payload", payload);
        if (payload.class_name) {
            console.log("inside class_name if");
            getClassList({
                page: "1",
                limit: "1000",
                sort_by: "id",
                order_by: "asc",
                school_id: schoolId,
            }).then((response) => {
                if (response.data.code == 1) {
                    console.log(
                        payload.class_name,
                        "getClassList",
                        response.data.data.list
                    );
                    payload.class_id = 0;
                    response.data.data.list.some((item) => {
                        if (
                            item.name.toLowerCase().trim() ===
                            payload.class_name.toLowerCase().trim()
                        ) {
                            payload.class_id = item.id;
                            return true;
                        }
                    });
                    console.log("classid", payload.class_id);
                    if (payload.class_id) {
                        console.log("class id", payload.class_id);
                        getUserList({
                            user_type: "teacher",
                            page: "1",
                            limit: "1000",
                            sort_by: "id",
                            order_by: "desc",
                            school_id: schoolId,
                        }).then((response) => {
                            if (response.data.code == 1) {
                                console.log(
                                    payload,
                                    "getUserList",
                                    response.data.data.list
                                );
                                if (payload.class_teacher) {
                                    const class_teacher_list =
                                        payload.class_teacher
                                            .split("&&")
                                            .map((item) =>
                                                item.toLowerCase().trim()
                                            );
                                    console.log(class_teacher_list);
                                    payload.class_teacher_ids = [];
                                    // const response_class_teachers =
                                    //     response.data.data.list.map((item) =>
                                    //         item.name.toLowerCase().trim()
                                    //     );
                                    payload.class_teacher_ids =
                                        class_teacher_list.map(
                                            (class_teacher) => {
                                                const obj =
                                                    response.data.data.list.find(
                                                        (teacher) =>
                                                            teacher.name.toLowerCase().trim() ===
                                                            class_teacher
                                                    );
                                                if (obj) return obj.id;
                                                else return null;
                                            }
                                        );
                                    console.log(
                                        "class teacher ids",
                                        payload.class_teacher_ids,
                                        null in payload.class_teacher_ids
                                    );
                                    if (
                                        !payload.class_teacher_ids.length ||
                                        payload.class_teacher_ids.includes(null)
                                    ) {
                                        updateFeedback(
                                            `Failed adding division <${
                                                payload.class_name
                                            } - ${payload.name}> at row ${
                                                index + 2
                                            }; Unable to match class teacher`,
                                            "error"
                                        );
                                        return;
                                    } else if (payload.teacher) {
                                        const teacher_list =
                                            payload.teacher
                                                .split("&&")
                                                .map((item) =>
                                                    item.toLowerCase().trim()
                                                );
                                        console.log(teacher_list);
                                        payload.teacher_ids = [];
                                        // const response_class_teachers =
                                        //     response.data.data.list.map((item) =>
                                        //         item.name.toLowerCase().trim()
                                        //     );
                                        payload.teacher_ids =
                                            teacher_list.map(
                                                (teacher_name) => {
                                                    const obj =
                                                        response.data.data.list.find(
                                                            (teacher) =>
                                                                teacher.name.toLowerCase().trim() ===
                                                                teacher_name
                                                        );
                                                    if (obj) return obj.id;
                                                    else return null;
                                                }
                                            );
                                        console.log(
                                            "teacher ids",
                                            payload.teacher_ids
                                        );
                                        if (!payload.teacher_ids.length ||
                                            payload.teacher_ids.includes(null)) {
                                            updateFeedback(
                                                `Failed adding division <${
                                                    payload.class_name
                                                } - ${payload.name}> at row ${
                                                    index + 2
                                                }; Unable to match teacher`,
                                                "error"
                                            );
                                            return;
                                        } else {
                                            createDevision({
                                                school_id: schoolId,
                                                class_id: payload.class_id,
                                                name: payload.name,
                                                ar_name: payload.name,
                                                tu_name: payload.name,
                                                teacher_ids: payload.teacher_ids,
                                                class_teacher_ids:
                                                    payload.class_teacher_ids,
                                                cover_image:
                                                    payload.cover_image,
                                                logo_image: payload.logo_image,
                                            }).then((response) => {
                                                console.log(response);
                                                if (response.data.code == 1) {
                                                    updateFeedback(
                                                        `Added division: ${payload.class_name} - ${payload.name} successfully`,
                                                        "success"
                                                    );
                                                    const outputJSON = {
                                                        ...payload,
                                                        inputFileRow: index + 2,
                                                        school_id: schoolId,
                                                    };
                                                    const temp =
                                                        setAddedDataList(
                                                            outputJSON
                                                        );
                                                    console.log(
                                                        "after adding to list",
                                                        temp
                                                    );
                                                } else {
                                                    updateFeedback(
                                                        `Failed adding teacher <${
                                                            payload.name
                                                        }> at row ${
                                                            index + 2
                                                        }; Message: ${
                                                            response.data
                                                                .message
                                                        }`,
                                                        "error"
                                                    );
                                                }
                                            });
                                        }
                                    } else {
                                        updateFeedback(
                                            `Failed adding division <${
                                                payload.class_name
                                            } - ${payload.name}> at row ${
                                                index + 2
                                            }; Teacher not provided`,
                                            "error"
                                        );
                                        return;
                                    }
                                } else {
                                    updateFeedback(
                                        `Failed adding division <${
                                            payload.class_name
                                        } - ${payload.name}> at row ${
                                            index + 2
                                        }; Class Teacher not provided`,
                                        "error"
                                    );
                                    return;
                                }
                            } else {
                                updateFeedback(
                                    `Failed adding division <${
                                        payload.class_name
                                    } - ${payload.name}> at row ${
                                        index + 2
                                    }; Failed getting teacher`,
                                    "error"
                                );
                                return;
                            }
                        });
                    } else {
                        updateFeedback(
                            `Failed adding division <${payload.class_name} - ${
                                payload.name
                            }> at row ${index + 2}; Failed getting class`,
                            "error"
                        );
                        return;
                    }
                } else {
                    updateFeedback(
                        `Failed adding division <${payload.class_name} - ${
                            payload.name
                        }> at row ${index + 2}; Failed getting class`,
                        "error"
                    );
                }
            });
        } else {
            updateFeedback(
                `Failed adding division <${payload.class_name} - ${
                    payload.name
                }> at row ${index + 2}; Class not provided`,
                "error"
            );
        }
    });
    resetData();
};

export default createBulkDivision;
