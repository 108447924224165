import React, { useState, useEffect } from "react";
//Datatable Modules
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
    getSchooldDetails,
    getClassList,
    getDevisionList,
    getUserList,
    deleteClass,
    deleteDevision,
    deleteUser,
    updateUserStatus,
} from "../../api/apiHandler";
import Alert from "../../components/Alert/Alert";
import { renderToStaticMarkup } from "react-dom/server";

export default function SchoolDetails(props) {
    let { schoolId } = useParams();

    const [schoolDetails, setSchoolDetails] = useState(null);

    const [myTeachertist, setTeacherList] = useState(null);

    const [myClassList, setClasslList] = useState(null);

    const [myDevisionList, setDevisionList] = useState(null);

    const [myStudentist, setStudentList] = useState(null);

    const [msg, setMsg] = useState(null);

    const showAlert = (msgDisplay, type) => {
        setMsg({
            msg: msgDisplay,
            type: type,
        });
    };

    const getClassName = class_id => {
        const class_obj = myClassList.find(element => element.id === class_id);
        if(class_obj && class_obj.name) return class_obj.name;
        return undefined;
    } 

    useEffect(() => {
        let mounted = true;
        props.isLoader(true);
        getSchooldDetails({ school_id: schoolId }).then((resposnse) => {
            // console.log(resposnse);
            if (resposnse.data.code == 1) {
                setSchoolDetails(resposnse.data.data);
            }
        });
        getClassList({
            page: "1",
            limit: "1000",
            sort_by: "id",
            order_by: "asc",
            school_id: schoolId,
        }).then((resposnse) => {
            // console.log("classlist", resposnse);
            if (resposnse.data.code == 1) {
                setClasslList(resposnse.data.data.list);
            } else {
                setClasslList([]);
            }
        });
        getDevisionList({
            page: "1",
            limit: "1000",
            sort_by: "id",
            order_by: "asc",
            school_id: schoolId,
        }).then((resposnse) => {
            // console.log("division list", resposnse);
            if (resposnse.data.code == 1) {
                setDevisionList(resposnse.data.data.list);
            } else {
                setDevisionList([]);
            }
        });
        getUserList({
            user_type: "student",
            page: "1",
            limit: "1000",
            sort_by: "id",
            order_by: "desc",
            school_id: schoolId,
        }).then((resposnse) => {
            if (resposnse.data.code == 1) {
                setStudentList(resposnse.data.data.list);
            } else {
                setStudentList([]);
            }
        });

        getUserList({
            user_type: "teacher",
            page: "1",
            limit: "1000",
            sort_by: "id",
            order_by: "desc",
            school_id: schoolId,
        }).then((resposnse) => {
            // console.log(resposnse);
            if (resposnse.data.code == 1) {
                setTeacherList(resposnse.data.data.list);
            } else {
                setTeacherList([]);
            }
        });

        props.isLoader(false);

        return () => (mounted = false);
    }, []);

    let navigate = useNavigate();

    const redirectCreateTeacher = () => {
        let path = "/createTeacher/" + schoolId;
        navigate(path);
    };

    const redirectBulkCreateTeacher = () => {
        let path = "/bulkCreateTeacher/" + schoolId;
        navigate(path);
    };

    const redirectCreateClass = () => {
        let path = "/createClass/" + schoolId;
        navigate(path);
    };

    const redirectCreateDevision = () => {
        let path = "/createDivision/" + schoolId;
        navigate(path);
    };

    const redirectBulkCreateDivision = () => {
        let path = "/bulkCreateDivision/" + schoolId;
        navigate(path);
    };

    $(document).ready(function () {
        $("#mangeUser").DataTable({
            pagingType: "full_numbers",
            pageLength: 10,
            processing: true,
            dom: "Bfrtip",
            buttons: ["copy", "csv", "print"],
            bDestroy: true,
        });
        $("#mangeDevison").DataTable({
            pagingType: "full_numbers",
            pageLength: 10,
            processing: true,
            dom: "Bfrtip",
            buttons: ["copy", "csv", "print"],
            bDestroy: true,
        });
        $("#mangeStudent").DataTable({
            pagingType: "full_numbers",
            pageLength: 10,
            processing: true,
            order: [[0, "desc"]],
            dom: "Bfrtip",
            buttons: ["copy", "csv", "print"],
            bDestroy: true,
        });
        $("#mangeTeacher").DataTable({
            pagingType: "full_numbers",
            pageLength: 10,
            processing: true,
            order: [[0, "desc"]],
            dom: "Bfrtip",
            buttons: ["copy", "csv", "print"],
            bDestroy: true,
        });
    });

    function deleteClassIdWise(classId) {
        console.log("Call Delete" + classId);
        if (window.confirm("Are you sure you want to delete?")) {
            deleteClass({ school_id: schoolId, class_id: classId }).then(
                (resposnse) => {
                    console.log(resposnse);
                    if (resposnse.data.code == 1) {
                        window.scrollTo(0, 0);
                        $("#mangeUser")
                            .DataTable()
                            .row("#classId" + classId)
                            .remove()
                            .draw();
                        showAlert(resposnse.data.message, "success");
                    } else {
                        window.scrollTo(0, 0);
                        showAlert(resposnse.data.message, "danger");
                    }
                }
            );
        }
    }

    function deleteDevisionIdWise(devisionId) {
        console.log("Call Delete" + devisionId);
        if (window.confirm("Are you sure you want to delete?")) {
            deleteDevision({
                school_id: schoolId,
                division_id: devisionId,
            }).then((resposnse) => {
                console.log(resposnse);
                if (resposnse.data.code == 1) {
                    window.scrollTo(0, 0);
                    $("#mangeDevison")
                        .DataTable()
                        .row("#devisionId" + devisionId)
                        .remove()
                        .draw();
                    showAlert(resposnse.data.message, "success");
                } else {
                    window.scrollTo(0, 0);
                    showAlert(resposnse.data.message, "danger");
                }
            });
        }
    }

    const redirectCreateStudent = () => {
        let path = "/createStudentBulk/" + schoolId;
        navigate(path);
    };

    const redirectBulkCreateStudent = () => {
        let path = "/bulkCreateStudent/" + schoolId;
        navigate(path);
    };

    function deleteUserIdWise(userId) {
        console.log("Call Delete" + userId);
        if (window.confirm("Are you sure you want to delete?")) {
            props.isLoader(true);
            deleteUser({ user_id: userId }).then((resposnse) => {
                console.log(resposnse);
                if (resposnse.data.code == 1) {
                    $("#mangeStudent")
                        .DataTable()
                        .row("#userId" + userId)
                        .remove()
                        .draw();
                } else {
                }
                props.isLoader(false);
            });
        }
    }

    function deleteTeacherIdWise(userId) {
        console.log("Call Delete" + userId);
        if (window.confirm("Are you sure you want to delete?")) {
            props.isLoader(true);
            deleteUser({ user_id: userId }).then((resposnse) => {
                console.log(resposnse);
                if (resposnse.data.code == 1) {
                    $("#mangeTeacher")
                        .DataTable()
                        .row("#teacherId" + userId)
                        .remove()
                        .draw();
                } else {
                }
                props.isLoader(false);
            });
        }
    }

    const statusChangeListner = (position) => {
        console.log(position);

        props.isLoader(true);

        console.log("Call api change status");

        let apiData = {};
        if (myStudentist[position].is_active === 1) {
            apiData = { user_id: myStudentist[position].id, status: 0 };
        } else {
            apiData = { user_id: myStudentist[position].id, status: 1 };
        }

        console.log(apiData);

        updateUserStatus(apiData).then((resposnse) => {
            console.log(resposnse);
            props.isLoader(false);
            if (resposnse.data.code == 1) {
                callAfterApiChangeStatus(position, true);
            } else {
                callAfterApiChangeStatus(position, false);
            }
        });
    };

    const callAfterApiChangeStatus = (position, status) => {
        if (status) {
            if (myStudentist[position].is_active === 1) {
                myStudentist[position].is_active = 0;
            } else {
                myStudentist[position].is_active = 1;
            }
        }

        setStudentList(myStudentist);

        manageCheckStatus(position);
    };

    const manageCheckStatus = (position) => {
        var item = myStudentist[position];

        console.log(position);

        var data = renderToStaticMarkup(
            renderStatusUiText(myStudentist[position])
        );
        $("#mangeStudent")
            .DataTable()
            .row("#teacherId" + item.id)
            .cell("#changeStatus" + item.id)
            .data(data)
            .draw(false);
    };

    const statusChangeListnerTeacher = (position) => {
        props.isLoader(true);

        let apiData = {};
        if (myTeachertist[position].is_active === 1) {
            apiData = { user_id: myTeachertist[position].id, status: 0 };
        } else {
            apiData = { user_id: myTeachertist[position].id, status: 1 };
        }

        updateUserStatus(apiData).then((resposnse) => {
            props.isLoader(false);
            if (resposnse.data.code == 1) {
                callAfterApiChangeStatusTeacher(position, true);
            } else {
                callAfterApiChangeStatusTeacher(position, false);
            }
        });
    };

    const callAfterApiChangeStatusTeacher = (position, status) => {
        if (status) {
            if (myTeachertist[position].is_active === 1) {
                myTeachertist[position].is_active = 0;
            } else {
                myTeachertist[position].is_active = 1;
            }
        }

        setTeacherList(myTeachertist);

        manageCheckStatusTeacher(position);
    };

    const manageCheckStatusTeacher = (position) => {
        var item = myTeachertist[position];

        console.log(position);

        var data = renderToStaticMarkup(
            renderStatusUiText(myTeachertist[position])
        );
        $("#mangeTeacher")
            .DataTable()
            .row("#teacherId" + item.id)
            .cell("#changeStatus" + item.id)
            .data(data)
            .draw(false);
    };

    const renderStatusUiText = (item) => {
        return item.is_active === 1 ? (
            <>
                <span className="badge badge-success">Active</span>
            </>
        ) : (
            <>
                <span className="badge badge-danger">Inactive</span>
            </>
        );
    };

    if (
        schoolDetails == null ||
        myTeachertist == null ||
        myClassList == null ||
        myDevisionList == null ||
        myStudentist == null
    )
        return null;
    

    return (
        <section className="content home">
            <Alert alert={msg} />
            <div className="container-fluid">
                <div className="row clearfix">
                    <div className="col-lg-12 col-md-12 col-sm-12 m-t-15">
                        <div className="card">
                            <div className="header">
                                <h2> School Details </h2>
                            </div>
                            <div className="body">
                                <div className="row justify-content-center align-self-center">
                                    <div className="col-xl-4 align-self-center m-b-15">
                                        <img
                                            className="img-fluid img-thumbnail"
                                            src={schoolDetails.logo}
                                            alt={schoolDetails.logo}
                                        />
                                    </div>
                                </div>
                                <div className="row justify-content-center align-self-center">
                                    <div className="col-xl-4 align-self-center m-b-15">
                                        <img
                                            className="img-fluid img-thumbnail"
                                            src={schoolDetails.cover_image}
                                            alt={schoolDetails.cover_image}
                                        />
                                    </div>
                                </div>
                                <div className="tab-content">
                                    <div
                                        role="tabpanel"
                                        className="tab-pane active"
                                        id="official"
                                        aria-expanded="true"
                                    >
                                        <li className="list-group-item">
                                            <b className="mr-2">Name : </b>{" "}
                                            {schoolDetails.name}
                                        </li>
                                        <li className="list-group-item">
                                            <b className="mr-2">Name ar: </b>{" "}
                                            {schoolDetails.ar_name}
                                        </li>
                                        <li className="list-group-item">
                                            <b className="mr-2">Name tu: </b>{" "}
                                            {schoolDetails.tu_name}
                                        </li>
                                        <li className="list-group-item">
                                            <b className="mr-2">
                                                School Location :{" "}
                                            </b>{" "}
                                            {schoolDetails.location}
                                        </li>
                                        <li className="list-group-item">
                                            <b className="mr-2">
                                                School Phone Number :{" "}
                                            </b>{" "}
                                            {schoolDetails.country_code}{" "}
                                            {schoolDetails.phone_number}
                                        </li>
                                        <li className="list-group-item">
                                            <b className="mr-2">
                                                School Email Id :{" "}
                                            </b>{" "}
                                            {schoolDetails.email}
                                        </li>
                                        <li className="list-group-item">
                                            <b className="mr-2">
                                                School Website :{" "}
                                            </b>{" "}
                                            {schoolDetails.website_url}
                                        </li>
                                        <li className="list-group-item">
                                            <b className="mr-2">Status : </b>{" "}
                                            {schoolDetails.is_active == 1
                                                ? "Active"
                                                : "Inactive"}
                                        </li>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="card">
                            <div className="header">
                                <h2>Teacher Details </h2>
                                <button
                                    type="button"
                                    className="btn btn-raised btn-warning m-t-15 m-b-15 ml-2 waves-effect right"
                                    onClick={redirectBulkCreateTeacher}
                                >
                                    Bulk Create
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-raised btn-primary m-t-15 m-b-15 waves-effect right"
                                    onClick={redirectCreateTeacher}
                                >
                                    Create
                                </button>
                            </div>
                            <div className="body">
                                <table
                                    id="mangeTeacher"
                                    className="table table-bordered table-striped table-hover dataTable js-exportable"
                                >
                                    <thead>
                                        <tr>
                                            <th>Created Date</th>
                                            <th>User Id</th>
                                            <th>Username</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>School Name</th>
                                            <th>Status</th>
                                            <th>Status Action</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>Created Date</th>
                                            <th>User Id</th>
                                            <th>Username</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>School Name</th>
                                            <th>Status</th>
                                            <th>Status Action</th>
                                            <th>Action</th>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        {myTeachertist &&
                                            myTeachertist.map((item, index) => {
                                                return (
                                                    <tr
                                                        id={
                                                            "teacherId" +
                                                            item.id
                                                        }
                                                        key={item.id}
                                                    >
                                                        <td>
                                                            {item.created_at}
                                                        </td>
                                                        <td>{item.id}</td>
                                                        <td>{item.username}</td>
                                                        <td>{item.name}</td>
                                                        <td>{item.email}</td>
                                                        <td>
                                                            {item.school_name}
                                                        </td>
                                                        <td
                                                            id={
                                                                "changeStatus" +
                                                                item.id
                                                            }
                                                            className="text-center"
                                                        >
                                                            {item.is_active ===
                                                            1 ? (
                                                                <>
                                                                    <span className="badge badge-success">
                                                                        Active
                                                                    </span>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <span className="badge badge-danger">
                                                                        Inactive
                                                                    </span>
                                                                </>
                                                            )}
                                                        </td>
                                                        <td>
                                                            <i
                                                                onClick={() =>
                                                                    statusChangeListnerTeacher(
                                                                        index
                                                                    )
                                                                }
                                                                className="zmdi zmdi-refresh-alt"
                                                            >
                                                                {" "}
                                                            </i>
                                                        </td>
                                                        <td className="text-right">
                                                            <div className="action-btn">
                                                                <Link
                                                                    to={`/teacherDetails/${item.id}`}
                                                                    className="btn btn-default col-green"
                                                                >
                                                                    <i className="zmdi zmdi-eye"></i>
                                                                </Link>
                                                                <Link
                                                                    to={`/editTeacher/${item.id}`}
                                                                    className="btn btn-default col-green"
                                                                >
                                                                    <i className="zmdi zmdi-edit"></i>
                                                                </Link>
                                                                <a
                                                                    onClick={() =>
                                                                        deleteTeacherIdWise(
                                                                            item.id
                                                                        )
                                                                    }
                                                                    className="btn btn-default col-red"
                                                                >
                                                                    <i className="zmdi zmdi-delete"></i>
                                                                </a>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    

            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card">                    
                    <div className="header">
                        <h2>Class Details </h2>
                        <button type="button" className="btn btn-raised btn-primary m-t-15 m-b-15 waves-effect right" onClick={redirectCreateClass}>Create</button>
                    </div>
                    <div className="body">
                        <table id="mangeUser" className="table table-bordered table-striped table-hover dataTable js-exportable">
                            <thead>
                                <tr>
                                    <th>Create Date</th>
                                    <th>Id</th>
                                    <th>Name</th>
                                    <th>Name ar</th>
                                    <th>Name tu</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tfoot>
                            <tr>
                                    <th>Create Date</th>
                                    <th>Id</th>
                                    <th>Name</th>
                                    <th>Name ar</th>
                                    <th>Name tu</th>
                                    <th>Action</th>
                            </tr>
                            </tfoot>
                            <tbody id="classId">
                                { 
                                myClassList && myClassList.map((item)=>{
                                    return(
                                        <tr id={"classId"+item.id}>
                                        <td>{item.created_at}</td>
                                        <td>{item.id}</td>
                                        <td>{item.name}</td>
                                        <td>{item.ar_name}</td>
                                        <td>{item.tu_name}</td>
                                        <td className="text-right">
                                        <div className='action-btn'>                        
                                           <Link to={`/editClass/${item.id}`} className="btn btn-default col-green"><i className="zmdi zmdi-edit"></i></Link>
                                           <a onClick={()=>deleteClassIdWise(item.id)} className="btn btn-default col-red"><i className="zmdi zmdi-delete"></i></a>
                                        </div>    
                                        </td>
                                    </tr>    
                                    )
    
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
           
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="card">
                            <div className="header">
                                <h2>Division Details </h2>
                                <button
                                    type="button"
                                    className="btn btn-raised btn-warning m-t-15 m-b-15 ml-2 waves-effect right"
                                    onClick={redirectBulkCreateDivision}
                                >
                                    Bulk Create
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-raised btn-primary m-t-15 m-b-15 waves-effect right"
                                    onClick={redirectCreateDevision}
                                >
                                    Create
                                </button>
                            </div>
                            <div className="body">
                                <table
                                    id="mangeDevison"
                                    className="table table-bordered table-striped table-hover dataTable js-exportable"
                                >
                                    <thead>
                                        <tr>
                                            <th>Create Date</th>
                                            <th>Id</th>
                                            <th>Name</th>
                                            <th>Name ar</th>
                                            <th>Name tu</th>
                                            <th>Class Name</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>Create Date</th>
                                            <th>Id</th>
                                            <th>Name</th>
                                            <th>Name ar</th>
                                            <th>Name tu</th>
                                            <th>Class Name</th>
                                            <th>Action</th>
                                        </tr>
                                    </tfoot>
                                    <tbody id="devisionId">
                                        {myDevisionList &&
                                            myDevisionList.map((item) => {
                                                return (
                                                    <tr
                                                        id={
                                                            "devisionId" +
                                                            item.id
                                                        }
                                                        key={item.id}
                                                    >
                                                        <td>
                                                            {item.created_at}
                                                        </td>
                                                        <td>{item.id}</td>
                                                        <td>{item.name}</td>
                                                        <td>{item.ar_name}</td>
                                                        <td>{item.tu_name}</td>
                                                        <td>{getClassName(item.class_id)}</td>
                                                        <td className="text-right">
                                                            <div className="action-btn">
                                                                <Link
                                                                    to={`/divisionDetails/${item.id}`}
                                                                    className="btn btn-default col-green"
                                                                >
                                                                    <i className="zmdi zmdi-eye"></i>
                                                                </Link>
                                                                <Link
                                                                    to={`/editDivision/${item.school_id}&${item.id}`}
                                                                    className="btn btn-default col-green"
                                                                >
                                                                    <i className="zmdi zmdi-edit"></i>
                                                                </Link>
                                                                <a
                                                                    onClick={() =>
                                                                        deleteDevisionIdWise(
                                                                            item.id
                                                                        )
                                                                    }
                                                                    className="btn btn-default col-red"
                                                                >
                                                                    <i className="zmdi zmdi-delete"></i>
                                                                </a>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="card">
                            <div className="header">
                                <h2>Student List</h2>
                                <button
                                    type="button"
                                    className="btn btn-raised btn-warning m-t-15 m-b-15 ml-2 waves-effect right"
                                    onClick={redirectBulkCreateStudent}
                                >
                                    Bulk Create
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-raised btn-primary m-t-15 m-b-15 waves-effect right"
                                    onClick={redirectCreateStudent}
                                >
                                    Create
                                </button>
                            </div>
                            <div className="body">
                                <table
                                    id="mangeStudent"
                                    className="table table-bordered table-striped table-hover dataTable js-exportable"
                                >
                                    <thead>
                                        <tr>
                                            <th>Created Date</th>
                                            <th>User Id</th>
                                            <th>Username</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Password</th>
                                            <th>Class Id</th>
                                            <th>Division Id</th>
                                            <th>Status</th>
                                            <th>Status Action</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>Created Date</th>
                                            <th>User Id</th>
                                            <th>Username</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Password</th>
                                            <th>Class Id</th>
                                            <th>Division Id</th>
                                            <th>Status</th>
                                            <th>Status Action</th>
                                            <th>Action</th>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        {myStudentist &&
                                            myStudentist.map((item, index) => {
                                                return (
                                                    <tr
                                                        id={"userId" + item.id}
                                                        key={item.id}
                                                    >
                                                        <td>
                                                            {item.created_at}
                                                        </td>
                                                        <td>{item.id}</td>
                                                        <td>{item.username}</td>
                                                        <td>{item.name}</td>
                                                        <td>{item.email}</td>
                                                        <td>{item.password}</td>
                                                        <td>{item.class_id}</td>
                                                        <td>
                                                            {item.division_id}
                                                        </td>
                                                        <td
                                                            id={
                                                                "changeStatus" +
                                                                item.id
                                                            }
                                                            className="text-center"
                                                        >
                                                            {item.is_active ===
                                                            1 ? (
                                                                <>
                                                                    <span className="badge badge-success">
                                                                        Active
                                                                    </span>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <span className="badge badge-danger">
                                                                        Inactive
                                                                    </span>
                                                                </>
                                                            )}
                                                        </td>
                                                        <td>
                                                            <i
                                                                onClick={() =>
                                                                    statusChangeListner(
                                                                        index
                                                                    )
                                                                }
                                                                className="zmdi zmdi-refresh-alt"
                                                            >
                                                                {" "}
                                                            </i>
                                                        </td>

                                                        <td className="text-right">
                                                            <div className="action-btn">
                                                                <Link
                                                                    to={`/studentDetails/${item.id}`}
                                                                    className="btn btn-default col-green"
                                                                >
                                                                    <i className="zmdi zmdi-eye"></i>
                                                                </Link>
                                                                <Link
                                                                    to={`/editStudent/${item.id}`}
                                                                    className="btn btn-default col-green"
                                                                >
                                                                    <i className="zmdi zmdi-edit"></i>
                                                                </Link>
                                                                <a
                                                                    onClick={() =>
                                                                        deleteUserIdWise(
                                                                            item.id
                                                                        )
                                                                    }
                                                                    className="btn btn-default col-red"
                                                                >
                                                                    <i className="zmdi zmdi-delete"></i>
                                                                </a>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
