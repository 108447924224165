import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  createAvatar,
  updateAvatar,
  getAvatarDetails,
  changePassword,
} from "../../api/apiHandler";

export default function ChangePassword(props) {
  const initalValue = {
    newPassword: "",
    currentPassword: "",
    confirmPassword: "",
  };
  const [formValues, setFormValues] = useState(initalValue);
  const clickSubmitButton = (e) => {
    e.preventDefault();
    changePassword({
      old_password: formValues.currentPassword,
      new_password: formValues.newPassword,
    }).then((resposnse) => {
      console.log(resposnse);
      if (resposnse.data.code == 1) {
        // setScoreList(resposnse.data.data)
      } else {
        // setScoreList([])
      }
    });
  };
  const handleChange = (e) => {
    if (e.target.value.trim() == "") {
      e.target.value = e.target.value.trim();
    }
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  return (
    <section className="content">
      <div className="container-fluid">
        <div className="row clearfix ">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="card m-t-15">
              <div className="header">
                <h2>{"Change Password"}</h2>
              </div>
              <div className="body">
                <form
                  id="form_validation"
                  method=""
                  onSubmit={clickSubmitButton}
                  action="#"
                >
                  <label htmlFor="name">Current Password</label>
                  <div className="form-group">
                    <div className="form-line">
                      <input
                        type="password"
                        placeholder="Current Password"
                        className="form-control"
                        id="currentPassword"
                        name="currentPassword"
                        required
                        value={formValues.currentPassword}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <label htmlFor="user_name">New Password</label>
                  <div className="form-group">
                    <div className="form-line">
                      <input
                        type="password"
                        placeholder="New Password"
                        className="form-control"
                        id="newPassword"
                        name="newPassword"
                        required
                        value={formValues.newPassword}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <button
                    className="btn btn-raised btn-primary waves-effect"
                    type="submit"
                  >
                    SUBMIT
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
