import {
    createUser,
    getClassList,
    getDevisionList,
} from "../../api/apiHandler";
import { studentColumnMapping } from "../Common/ColumnMappings";
import { generatePassword, generateUsername } from "../Common/Utility";

const findClassId = (className, classList) => {
    const foundClass = classList.find(classObj => classObj.name.trim().toLowerCase() === className.trim().toLowerCase());
    if(foundClass && foundClass.id) return foundClass.id;
    else return undefined;
}

const findDivisionId = (class_id, divisionName, divisionList) => {
    console.log("class_id, divisionName, divisionList",class_id, divisionName, divisionList);
    const foundDivision = divisionList.find(division => division.name.trim().toLowerCase() === divisionName.trim().toLowerCase() && division.class_id === class_id);
    if(foundDivision && foundDivision.id) return foundDivision.id;
    else return undefined;
};

const createBulkStudent = async ({
    schoolId,
    updateFeedback,
    data,
    setAddedDataList,
    resetData,
}) => {
    console.log(schoolId);
    updateFeedback("Started executing process");
    updateFeedback("Mapping column names");

    const columnNamesMap = studentColumnMapping(Object.keys(data[0]));

    let breakExecution = false;

    console.log(columnNamesMap);
    columnNamesMap.forEach((value, columnName) => {
        if (!value) {
            updateFeedback(`Unknown column name: ${columnName}`, "error");
            breakExecution = true;
        }
    });

    if (breakExecution) return;

    const classListResponse = await getClassList({
        page: "1",
        limit: "1000",
        sort_by: "id",
        order_by: "asc",
        school_id: schoolId,
    });

    if (classListResponse.data.code !== 1) {
        updateFeedback(`Failed to fetch class list`, "error");
        return;
    }

    updateFeedback(`Fetched class list`, "success");

    const divisionListResponse = await getDevisionList({
        page: "1",
        limit: "1000",
        sort_by: "id",
        order_by: "asc",
        school_id: schoolId,
    });

    if (divisionListResponse.data.code !== 1) {
        updateFeedback(`Failed to fetch division list`, "error");
        return;
    }

    updateFeedback(`Fetched division list`, "success");

    console.log(classListResponse.data.data.list, divisionListResponse.data.data.list);

    const classList = classListResponse.data.data.list;
    const divisionList = divisionListResponse.data.data.list;

    updateFeedback("Starting API calls");
    data.forEach((student, index) => {
        let payload = {};
        columnNamesMap.forEach((value, columnName) => {
            payload[value] = student[columnName];
        });

        payload = {
            ...payload,
            school_id: schoolId,
            user_type: "student",
            avatar_id: "1",
        };

        updateFeedback(`Adding student: ${payload.name}`);
        if (!(payload.username && payload.username.trim().length > 5)) {
            updateFeedback(
                "Username length < 6 or does not exist! Generating username"
            );
            payload = {
                ...payload,
                username: generateUsername(payload.name),
            };
        }else {
            payload = {
                ...payload,
                username: payload.username.toLowerCase(),
            };
        }

        if (!(payload.password && payload.password.trim().length > 5)) {
            updateFeedback(
                "Password length < 6 or is empty! Generating password"
            );
            payload = {
                ...payload,
                password: generatePassword(6),
            };
        }

        if(!payload.class || !payload.division) {
            updateFeedback(`Must provide class and division at row ${index + 2}`, "error");
            return;
        }

        payload.class_id = findClassId(payload.class, classList);
        if(!payload.class_id) {
            updateFeedback(`Unable to find class <${payload.class}> at row ${index + 2}`, "error");
            return;
        }

        payload.division_id = findDivisionId(payload.class_id, payload.division, divisionList);
        if(!payload.division_id) {
            updateFeedback(`Unable to find division <${payload.division}> at row ${index + 2}`, "error");
            return;
        }

        console.log(payload);
        createUser(payload).then((response) => {
            console.log(response);
            if (response.data.code == 1) {
                updateFeedback(
                    `Added student: ${payload.name} successfully`,
                    "success"
                );
                const outputJSON = {
                    ...payload,
                    inputFileRow: index + 2,
                };
                const temp = setAddedDataList(outputJSON);
                console.log("after adding to list", temp);
            } else {
                updateFeedback(
                    `Failed adding student <${payload.name}> at row ${
                        index + 2
                    }; Message: ${response.data.message}`,
                    "error"
                );

                if (
                    response.data.message ===
                    "There is already an account registered to this username"
                ) {
                    updateFeedback("There is already an account registered to this username. Generating new username and trying again");
                    payload = {
                        ...payload,
                        username: generateUsername(payload.username, true),
                    };
                    createUser(payload).then((response) => {
                        console.log(response);
                        if (response.data.code == 1) {
                            updateFeedback(
                                `Added student: ${payload.name} successfully`,
                                "success"
                            );
                            const outputJSON = {
                                ...payload,
                                inputFileRow: index + 2,
                            };
                            const temp = setAddedDataList(outputJSON);
                            console.log("after adding to list", temp);
                        } else {
                            updateFeedback(
                                `Failed adding student <${
                                    payload.name
                                }> at row ${index + 2}; Message: ${
                                    response.data.message
                                }`,
                                "error"
                            );
                        }
                    });
                }
            }
        });
    });
    resetData();
};

export default createBulkStudent;
