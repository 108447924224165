import { createUser } from "../../api/apiHandler";
import { teacherColumnMapping } from "../Common/ColumnMappings";
import { generatePassword, generateUsername } from "../Common/Utility";

const createBulkTeacher = ({
    schoolId,
    updateFeedback,
    data,
    setAddedDataList,
    resetData,
}) => {
    console.log(schoolId);
    updateFeedback("Started executing process");
    updateFeedback("Mapping column names");

    const columnNamesMap = teacherColumnMapping(Object.keys(data[0]));

    let breakExecution = false;

    console.log(columnNamesMap);
    columnNamesMap.forEach((value, columnName) => {
        if (!value) {
            updateFeedback(`Unknown column name: ${columnName}`, "error");
            breakExecution = true;
        }
    });

    if (breakExecution) return;

    updateFeedback("Starting API calls");
    data.forEach((teacher, index) => {
        let payload = {};
        columnNamesMap.forEach((value, columnName) => {
            payload[value] = teacher[columnName];
        });

        payload = {
            ...payload,
            school_id: schoolId,
            user_type: "teacher",
            avatar_id: "1",
            about_me: "Being a teacher !",
        };

        updateFeedback(`Adding teacher: ${payload.name}`);
        if (!(payload.username && payload.username.trim().length > 5)) {
            updateFeedback(
                "Username length < 6 or does not exist! Generating username"
            );
            payload = {
                ...payload,
                username: generateUsername(payload.name),
            };
        } else {
            payload = {
                ...payload,
                username: payload.username.toLowerCase(),
            };
        }

        if (!(payload.password && payload.password.trim().length > 5)) {
            updateFeedback(
                "Password length < 6 or is empty! Generating password"
            );
            payload = {
                ...payload,
                password: generatePassword(6),
            };
        }

        console.log(payload);
        createUser(payload).then((response) => {
            console.log(response);
            if (response.data.code == 1) {
                updateFeedback(
                    `Added teacher: ${payload.name} successfully`,
                    "success"
                );
                const outputJSON = {
                    ...payload,
                    inputFileRow: index + 2,
                };
                const temp = setAddedDataList(outputJSON);
                console.log("after adding to list", temp);
            } else {
                updateFeedback(
                    `Failed adding teacher <${payload.name}> at row ${
                        index + 2
                    }; Message: ${response.data.message}`,
                    "error"
                );

                if (
                    response.data.message ===
                    "There is already an account registered to this username"
                ) {
                    updateFeedback("Generating new username and trying again");
                    payload = {
                        ...payload,
                        username: generateUsername(payload.username, true),
                    };
                    createUser(payload).then((response) => {
                        console.log(response);
                        if (response.data.code == 1) {
                            updateFeedback(
                                `Added teacher: ${payload.name} successfully`,
                                "success"
                            );
                            const outputJSON = {
                                ...payload,
                                inputFileRow: index + 2,
                            };
                            const temp = setAddedDataList(outputJSON);
                            console.log("after adding to list", temp);
                        } else {
                            updateFeedback(
                                `Failed adding teacher <${
                                    payload.name
                                }> at row ${index + 2}; Message: ${
                                    response.data.message
                                }`,
                                "error"
                            );
                        }
                    });
                }
            }
        });
    });
    resetData();
};

export default createBulkTeacher;
