import React, { useState ,useEffect} from 'react'
//Datatable Modules
import 'jquery/dist/jquery.min.js'
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import $ from 'jquery'; 
import Heading from '../../components/Heading/Heading';
import { Link,useNavigate,useParams } from 'react-router-dom'
import {getUserList,deleteUser,updateUserStatus} from '../../api/apiHandler'
import Alert from '../../components/Alert/Alert';
import { renderToStaticMarkup } from 'react-dom/server'


export default function UserListingStudent(props) {

    const [myStudentist, setStudentList] = useState(null)

    useEffect(() => {
        let mounted = true;
        props.isLoader(true)
        getUserList({"user_type":"student","page":"1","limit":"1000","sort_by":"id","order_by":"desc"}).then((resposnse)=>{
            console.log(resposnse)
            if(resposnse.data.code==1){
                setStudentList(resposnse.data.data.list);
            }else{
                setStudentList([]);
            }
        })
        return () => mounted = false;
    }, []);

    let navigate = useNavigate(); 

    const redirectCreateStudent = ()=>{
        let path ="/createStudent"
        navigate(path)
    }

    $(document).ready(function () {
        $('#mangeUser').DataTable(
            {
                pagingType: 'full_numbers',
                  pageLength: 10,
                  processing: true,
                  order: [[0, 'desc']],
                  dom: 'Bfrtip',
                      buttons: ['copy', 'csv', 'print'
                      ],
                      "bDestroy": true
            }
        );
    });


    function deleteUserIdWise(userId){
        console.log("Call Delete"+userId)
        if(window.confirm("Are you sure you want to delete?")){
            props.isLoader(true)
            deleteUser({"user_id":userId}).then((resposnse)=>{
                console.log(resposnse);
                if(resposnse.data.code==1){
                    $('#mangeUser').DataTable().row("#userId"+userId).remove().draw();
                }else{
                }
                props.isLoader(false)
            });
        }
    } 

    const statusChangeListner = (position)=>{

        console.log(position) 
 
        props.isLoader(true)
 
        console.log("Call api change status")
 
        let apiData={}
        if(myStudentist[position].is_active===1){
             apiData={"user_id":myStudentist[position].id,"status":0}        
        }
        else{
             apiData={"user_id":myStudentist[position].id,"status":1}        
        }
 
        console.log(apiData)
 
        updateUserStatus(apiData).then((resposnse)=>{
         console.log(resposnse)
         props.isLoader(false)
         if(resposnse.data.code==1){
             callAfterApiChangeStatus(position,true)
         }else{
             callAfterApiChangeStatus(position,false)
         }
         })    
     }
 
     const callAfterApiChangeStatus = (position,status)=>{
 
         if(status){
 
             if(myStudentist[position].is_active===1){
                 myStudentist[position].is_active=0       
                }else{
                 myStudentist[position].is_active=1
                 }
         }
 
         setStudentList(myStudentist)
 
         manageCheckStatus(position)
 
     }
 
     const manageCheckStatus = (position)=>{
        var item=myStudentist[position];
 
        console.log(position) 
  
         var data =renderToStaticMarkup(renderStatusUiText(myStudentist[position]));
         $('#mangeUser').DataTable().row("#userId"+item.id).cell("#changeStatus"+item.id).data(data).draw(false);
  
     }
 
     const renderStatusUiText=(item)=>{
         return (
             item.is_active===1 ?
             <>
             <span class="badge badge-success">Active</span>
             </>
             :
             <>                                                
             <span class="badge badge-danger">Inactive</span>
             </>
 
         )
     }


    if (myStudentist==null) return null;
    props.isLoader(false)

    return (
    <section className="content home">
        <Heading title="Student"/>
        <div className="container-fluid"> 
        <div className="row clearfix">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card">
                    <div className="header">
                    <button type="button" className="btn btn-raised btn-primary m-t-15 m-b-15 waves-effect right" onClick={redirectCreateStudent}>Create</button>
                    </div>
                    <div className="body">
                        <table id="mangeUser" className="table table-bordered table-striped table-hover dataTable js-exportable">
                            <thead>
                                <tr>
                                    <th>Created Date</th>
                                    <th>UserId</th>
                                    <th>Username</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>School Name</th>
                                    <th>Status</th>
                                    <th>Status Action</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th>Created Date</th>
                                    <th>UserId</th>
                                    <th>Username</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>School Name</th>
                                    <th>Status</th>
                                    <th>Status Action</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                            <tbody>
                            { 
                            myStudentist && myStudentist.map((item,index)=>{
                                    return(
                                        <tr id={"userId"+item.id}>
                                            <td>{item.created_at}</td>
                                            <td>{item.id}</td>
                                            <td>{item.username}</td>
                                            <td>{item.name}</td>
                                            <td>{item.email}</td>
                                            <td><Link to={`/SchoolDetails/${item.school_id}`} className="col-green"><u >{item.school_name}</u></Link></td>
                                            <td id={"changeStatus"+item.id} className="text-center" > 
                                             {
                                                item.is_active===1 ?
                                                <>
                                                <span class="badge badge-success">Active</span>
                                                </>
                                                :
                                                <>                                                
                                                <span class="badge badge-danger">Inactive</span>
                                                </>
                                             }
                                            </td>
                                            <td>
                                            <i onClick={()=>statusChangeListner(index)} className="zmdi zmdi-refresh-alt"> </i>
                                            </td>
                                            <td className="text-right">
                                            <div className='action-btn'>                        
                                            <Link to={`/studentDetails/${item.id}`} className="btn btn-default col-green"><i className="zmdi zmdi-eye"></i></Link>
                                            <Link to={`/editStudent/${item.id}`} className="btn btn-default col-green"><i className="zmdi zmdi-edit"></i></Link>
                                            <a onClick={()=>deleteUserIdWise(item.id)} className="btn btn-default col-red"><i className="zmdi zmdi-delete"></i></a>
                                            </div>    
                                            </td>
                                        </tr>

                                        )
                                })}
                        </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </div>
        </div>
    </section>
    );
}
