import React, { useState ,useEffect} from 'react'
import 'jquery/dist/jquery.min.js'
import $ from 'jquery'; 
import Heading from '../../components/Heading/Heading';
import { Link ,useNavigate} from 'react-router-dom'
import {importConversationWBData} from '../../api/apiHandler'

const ImportWBConvData = async ({
    updateFeedback,
    data,
    setAddedDataList,
    resetData,
}) => {
    updateFeedback("Started executing process.");
    updateFeedback("Sending data to server. Awaiting response...");
    
    const reqData = {'data': data}
    importConversationWBData(reqData).then((response) => {
        //alert(JSON.stringify(response));
        updateFeedback(response.data.data.message);
        updateFeedback("Result is given below.");
        if (response.data.data.existingItemStr != '') {
            updateFeedback(response.data.data.existingItemStr);
        }
        if (response.data.data.importedItemStr != '') {
            updateFeedback(response.data.data.importedItemStr);
        }
        if ((response.data.data.importedItemStr == '') && (response.data.data.importedItemStr == '')) {
            updateFeedback("No data imported!");
        }
        alert(response.data.data.message);
    });
    resetData();
};

export default ImportWBConvData;