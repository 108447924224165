import React,{useState,useEffect} from 'react'
import Heading from '../../components/Heading/Heading';
import {getStaticPageDetails,updateStaticPageDetails} from '../../api/apiHandler'
import {Helmet} from "react-helmet";
import { useLocation } from 'react-router-dom'

export default function StaticPages(props) {

    const { state } = useLocation();
    let type =props.type;
    let lng =props.lng;
 
    const [contentData, setContentData] = useState(null)

    useEffect(() => {
        let mounted = true;
        props.isLoader(true)
        var data={"page_type":type,"language":lng}
        getStaticPageDetails(data).then((resposnse)=>{
            console.log(resposnse)
            props.isLoader(false)
            if(resposnse.data.code==1){
                setContentData(resposnse.data.data);
            }
        })
        return () => mounted = false;
    }, [type]);

    const onClickUpdate=()=>{
        const content=document.getElementById("summernote").value;
        props.isLoader(true)
        var data={"page_type":type,"language":lng,"content":content}
        updateStaticPageDetails(data).then((resposnse)=>{
            props.isLoader(false)
        })

    }

    if (contentData==null) return null;

  return (
    <section className="content home">
        <Heading title={props.title}/>
        <div className="container-fluid"> 
        <div className="row clearfix">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card">
                    <div className="header">
                    </div>
                    <div className="body">
                    <textarea style={{'width':'100%'}} rows="20" id='summernote'>
                    {contentData.content}
                        </textarea>
                        <div class="text-center">
                            <button type="button" class="btn btn-primary m-2" onClick={onClickUpdate}>Update</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <Helmet>
        <script>
            {
            `
            $(document).ready(function() {
                $('#summernote').summernote({
                    toolbar: [
                        ['style', ['bold', 'italic', 'underline', 'clear']],
                        ['font', ['strikethrough', 'superscript', 'subscript']],
                        ['fontname', ['fontname']],
                        ['fontsize', ['fontsize']],
                        ['color', ['color']],
                        ['para', ['ul', 'ol', 'paragraph']],
                        ['height', ['height']],
                        ['table', ['table']],
                        ['view', ['codeview']]                        
                      ]
                });

            });
            `
            }
        </script>

        </Helmet>
    </section>
  )
}
