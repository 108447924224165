import "./ConsoleOutput.css";

const ConsoleOutput = ({ messages }) => {
    const statusToClassMapping = {
        'info' : 'text-light',
        'success' : 'text-success',
        'error' : 'text-danger'
    };
    return (
        <div className="console bg-inverse container pb-2 pt-3">
            <div className="scrollable">
                {messages.map((message, index) => {
                    const status = statusToClassMapping[message.status] ? statusToClassMapping[message.status] : "text-light";
                    return(
                    <div key={index} className="row my-2">
                        <div className={`col-12 output ${status}`}>
                            <span className="symbol text-light">&gt;</span>{message.text}</div>
                        <div />
                    </div>
                );})}
            </div>
        </div>
    );
};

export default ConsoleOutput;
