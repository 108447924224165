import React, { useState ,useEffect} from 'react'
import { Link,useNavigate,useParams } from 'react-router-dom'
import {getFunZoneList,deleteFunZone,changeFunZoneStatus} from '../../api/apiHandler'
import Alert from '../../components/Alert/Alert';
//Datatable Modules
import 'jquery/dist/jquery.min.js'
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import $ from 'jquery'; 
import Heading from '../../components/Heading/Heading';
import { toBeChecked } from '@testing-library/jest-dom/dist/matchers';
import { renderToStaticMarkup } from 'react-dom/server'

export default function FunZoneListing(props) {

    const [myFunZoneList, setFunZoneList] = useState(null)

    useEffect(() => {
        let mounted = true;
        props.isLoader(true)
        getFunZoneList({"page":"1","limit":"1000","sort_by":"id","order_by":"desc"}).then((resposnse)=>{
            console.log(resposnse)
            if(resposnse.data.code==1){
                setFunZoneList(resposnse.data.data.list);
            }else{
                setFunZoneList([]);
            }
        })
        return () => mounted = false;
    }, []);


    $(document).ready(function () {
        $('#mangeUser').DataTable(
            {
                pagingType: 'full_numbers',
                  pageLength: 10,
                  order: [[0, 'desc']],
                  processing: true,
                  dom: 'Bfrtip',
                      buttons: ['copy', 'csv', 'print'
                      ],
                      "bDestroy": true
            }
        );
    });

    let navigate = useNavigate(); 

    const redirectCreateAdmin = ()=>{
        let path ="/createFunZone"
        navigate(path)
    }

    function deleteUserIdWise(userId){
        console.log("Call Delete"+userId)
        if(window.confirm("Are you sure you want to delete?")){
            props.isLoader(true)
            deleteFunZone({"funzone_id":userId}).then((resposnse)=>{
                console.log(resposnse);
                if(resposnse.data.code==1){
                    $('#mangeUser').DataTable().row("#userId"+userId).remove().draw();
                }else{
                }
                props.isLoader(false)
            });
        }
    }

    const statusChangeListner = (position)=>{

       console.log(position) 

       props.isLoader(true)

       console.log("Call api change status")

       let apiData={}
       if(myFunZoneList[position].is_active===1){
            apiData={"funzone_id":myFunZoneList[position].id,"status":0}        
       }
       else{
            apiData={"funzone_id":myFunZoneList[position].id,"status":1}        
       }

       console.log(apiData)

       changeFunZoneStatus(apiData).then((resposnse)=>{
        console.log(resposnse)
        props.isLoader(false)
        if(resposnse.data.code==1){
            callAfterApiChangeStatus(position,true)
        }else{
            callAfterApiChangeStatus(position,false)
        }
        })    
    }

    const callAfterApiChangeStatus = (position,status)=>{

        if(status){

            if(myFunZoneList[position].is_active===1){
                myFunZoneList[position].is_active=0       
               }else{
                myFunZoneList[position].is_active=1
                }
        }

        setFunZoneList(myFunZoneList)

        manageCheckStatus(position)

    }

    const manageCheckStatus = (position)=>{
       var item=myFunZoneList[position];

       console.log(position) 
 
        var data =renderToStaticMarkup(renderStatusUiText(myFunZoneList[position]));
        $('#mangeUser').DataTable().row("#userId"+item.id).cell("#changeStatus"+item.id).data(data).draw(false);
 
    }

    const renderStatusUiText=(item)=>{
        return (
            item.is_active===1 ?
            <>
            <span class="badge badge-success">Active</span>
            </>
            :
            <>                                                
            <span class="badge badge-danger">Inactive</span>
            </>

        )
    }


    if (myFunZoneList==null) return null;
    props.isLoader(false)

    return (
    <section className="content home">
        <Heading title="Manage Fun Zone"/>
        <div className="container-fluid"> 
        <div className="row clearfix">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card">
                    <div className="header">
                    <button type="button" className="btn btn-raised btn-primary m-t-15 m-b-15 waves-effect right" onClick={redirectCreateAdmin}>Create</button>
                    </div>
                    <div className="body">
                        <table id="mangeUser" className="table table-bordered table-striped table-hover dataTable js-exportable">
                            <thead>
                                <tr>
                                    <th>Created Date</th>
                                    <th>Title</th>
                                    <th>Category</th>
                                    <th>Content Type</th>
                                    <th>Description</th>
                                    <th>Status</th>
                                    <th>Status Action</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th>Created Date</th>
                                    <th>Title</th>
                                    <th>Category</th>
                                    <th>Content Type</th>
                                    <th>Description</th>
                                    <th>Status</th>
                                    <th>Status Action</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                            <tbody>

                            { 
                            myFunZoneList && myFunZoneList.map((item,index)=>{
                                    return(
                                        <tr id={"userId"+item.id}>
                                            <td>{item.created_at}</td>
                                            <td>{item.title}</td>
                                            <td>{item.category}</td>
                                            <td>{item.content_type}</td>
                                            <td>{item.description}</td>
                                            <td id={"changeStatus"+item.id} className="text-center" > 
                                             {
                                                item.is_active===1 ?
                                                <>
                                                <span class="badge badge-success">Active</span>
                                                </>
                                                :
                                                <>                                                
                                                <span class="badge badge-danger">Inactive</span>
                                                </>
                                             }
                                            </td>
                                            <td>
                                            <i onClick={()=>statusChangeListner(index)} className="zmdi zmdi-refresh-alt"> </i>
                                            </td>
                                            <td className="text-right">
                                            <div className='action-btn'>                        
                                            <Link to={`/funZoneDetails/${item.id}`} className="btn btn-default col-green"><i className="zmdi zmdi-eye"></i></Link>
                                            <Link to={`/editFunZone/${item.id}`} className="btn btn-default col-green"><i className="zmdi zmdi-edit"></i></Link>
                                            <a onClick={()=> {deleteUserIdWise(item.id)}} className="btn btn-default col-red"><i className="zmdi zmdi-delete"></i></a>
                                            </div>    
                                            </td>
                                        </tr>

                                        )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </section>
    );
}
