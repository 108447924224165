import { useRef, useState } from "react";
import "./FileDragAndDrop.css";
import { event } from "jquery";

const FileDragAndDrop = ({ onDrop, maxFiles = 1, formats }) => {
    /*Component State
        idle
        dragover
        dropped
        warning
    */
    const [dragState, setDragState] = useState("idle");
    const [feedback, setFeedback] = useState("");
    const inputFile = useRef(null);

    const resetState = () => {
        setDragState("");
        setFeedback("");
    };

    const handleDragEnter = (event) => {
        event.stopPropagation();
        event.preventDefault();
        console.log("dragenter");
        setDragState("dragover");
    };

    const handleDragLeave = (event) => {
        event.stopPropagation();
        event.preventDefault();
        console.log("dragleave");
        setDragState("idle");
    };

    const handleFileAdd = (files) => {
        files = Array.from(files);
        let message = "";
        if (!files || !files.length) return;
        if (files.length > maxFiles) {
            message = `Only ${maxFiles} file${
                maxFiles !== 1 ? "s" : ""
            } can be added at a time`;
            console.log(message);
            setFeedback(message);
            setDragState("warning");
            return;
        }
        if (
            formats &&
            files.some(
                (file) =>
                    !formats.some((format) =>
                        file.name.toLowerCase().endsWith(format.toLowerCase())
                    )
            )
        ) {
            message = `Only following file formats are acceptable: ${formats.join(
                ", "
            )}`;
            console.log(message);
            setFeedback(message);
            setDragState("warning");
            return;
        }

        message =
            "Added " +
            files
                .map((file) => {
                    return file.name;
                })
                .join(", ");
        console.log(message);
        setFeedback(message);
        setDragState("dropped");
        onDrop(files);
    };

    const handleDrop = (event) => {
        console.log("File(s) dropped");
        event.stopPropagation();
        event.preventDefault();
        handleFileAdd([...event.dataTransfer.files]);
    };

    const handleClick = (event) => {
        console.log("Clicked! Opening Explorer..");
        event.stopPropagation();
        event.preventDefault();
        setDragState("idle");
        setFeedback("");
        inputFile.current.click();
    };

    const handleFileInput = (event) => {
        const files = event.target.files;
        if (files.length) handleFileAdd(files);
    };

    return (
        <div
            className={`FileDragAndDrop mx-auto ${
                (dragState === "dragover" || dragState === "dropped") &&
                "dragover"
            } ${dragState === "warning" && "warning"}`}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onDragOver={(e) => e.preventDefault()}
            onClick={handleClick}
        >
            <div>
                <i className="zmdi zmdi-upload text-muted mb-2"></i>
                <input
                    type="file"
                    ref={inputFile}
                    name="FileDragAndDropInput"
                    id="FileDragAndDropInput"
                    multiple
                    className="d-none"
                    onChange={handleFileInput}
                    onClick={(e) => {
                        e.stopPropagation();
                        e.target.value = null;
                    }}
                />

                {feedback.length === 0 && (
                    <>
                        <span className="message mb-3">
                            Drag and Drop or{" "}
                            <span style={{ color: "blue" }}>
                                Click to Choose
                            </span>{" "}
                            file
                        </span>
                        <span className="sub-message">
                            {formats
                                .map(function (x) {
                                    return x.toUpperCase();
                                })
                                .join(" or ")}
                        </span>
                    </>
                )}
                {feedback.length > 0 && (
                    <span className="message mb-3">{feedback}</span>
                )}
            </div>
        </div>
    );
};

export default FileDragAndDrop;
