import React, {useEffect, useState, useCallback} from 'react'
import {useParams, useNavigate} from "react-router-dom";
import {getSchoolList,getClassList,getDevisionList,createUser,getUserDetails,editUser} from '../../api/apiHandler'
import $ from 'jquery';
import Alert from '../../components/Alert/Alert';
import Option from '../../components/Option/Option';
import Select from 'react-select';

export default function CreateStudent(props) {

    let navigate = useNavigate();

    let isEditFirstTimeSetDataSchool=""    
    let isEditFirstTimeSetDataClassDiviosn=""

    let tempStudentDetails={}

    const [passwordOld,setPasswordOld] = useState("")

    const [studentDetails, setStudentDetails] = useState(null)
    const [schoolList, setSchoolList] = useState([])
    const [classAndDevisionList, setClassAndDevisionList] = useState([])

    const [classList, setClasslList] = useState([])
    const [devisionList, setDevisionList] = useState([])
 
    const [selectedOptionSchool, setSelectedOption] = useState(null);
    const [selecteClassDevisiondOptionSchool, setClassDevisionOption] = useState(null);

    let id = useParams()

    const initalValue = {name: "", aboutMe: "", userName: "", email: "", password: "", phoneNumber: ""}
    const [formValues, setFormValues] = useState(initalValue)

    const [msg, setMsg] = useState(null);
    const showAlert = (msgDisplay, type) => {
        setMsg({
            msg: msgDisplay,
            type: type
        })
    }

    const handleSchoolChange =(data)=>{
        console.log(data)
        if(selectedOptionSchool !==data){
            props.isLoader(true)
            setSelectedOption(data)
            geClassListApi(data.value)
        }
    }
    const handleClassDiviosnChange=(data)=>{
        console.log(data)
        setClassDevisionOption(data)
    }


    const handleChange = (e) => {
        if (e.target.value.trim() == "") {
            e.target.value = e.target.value.trim()
        }
        const {name, value} = e.target;
        setFormValues({...formValues, [name]: value});
    }

    const handleChangePassword = (e) => {
        const {name, value} = e.target;
        setFormValues({...formValues, [name]: value});
    }

    const clickSubmitButton = (e) => {
        e.preventDefault();
        console.log(formValues)
        if (props.type == "e") {
            updateApiCall(formValues)
        } else {
            createApiCall(formValues)
        }
    }

    const createApiCall = (initValues) => {
        console.log("Create Api Call")
        props.isLoader(true)
        let schoolId=""

        let classId=""
        let devisionId=""

        if(selectedOptionSchool!==null){
            schoolId=selectedOptionSchool.value
        }

        if(selecteClassDevisiondOptionSchool!==null){
            classId=selecteClassDevisiondOptionSchool.value.split(",")[0]
            devisionId=selecteClassDevisiondOptionSchool.value.split(",")[1]
        }

        let apiData={"school_id":schoolId,"class_id":classId,"division_id":devisionId,"name":initValues.name,"username":initValues.userName,"password":initValues.password,"avatar_id":"1","user_type":"student","email":initValues.email,"phone_number":initValues.phoneNumber,"about_me":initValues.aboutMe}

        console.log(apiData)

        createUser(apiData).then((resposnse)=>{
            props.isLoader(false)
            console.log(resposnse)
            if(resposnse.data.code==1){
                navigate(-1)
            }else{
                showAlert(resposnse.data.message,"danger")
            }
        });        
    }

    const updateApiCall = (initValues) => {
        console.log("Update Api Call")
        props.isLoader(true)
        let schoolId=""

        let classId=""
        let devisionId=""

        if(selectedOptionSchool!==null){
            schoolId=selectedOptionSchool.value
        }

        if(selecteClassDevisiondOptionSchool!==null){
            classId=selecteClassDevisiondOptionSchool.value.split(",")[0]
            devisionId=selecteClassDevisiondOptionSchool.value.split(",")[1]
        }

        let apiData={"user_id":id.userId,"school_id":schoolId,"class_id":classId,"division_id":devisionId,"name":initValues.name,"username":initValues.userName,"password":initValues.password,"avatar_id":"1","user_type":"student","email":initValues.email,"phone_number":initValues.phoneNumber,"about_me":initValues.aboutMe}

        if(passwordOld===initValues.password){
            delete apiData.password
        }

        console.log(apiData)

        editUser(apiData).then((resposnse)=>{
            props.isLoader(false)
            console.log(resposnse)
            if(resposnse.data.code==1){
                navigate(-1)
            }else{
                showAlert(resposnse.data.message,"danger")
            }
        });
    }

    const geClassListApi = (id)=>{
        getClassList({"page":"1","limit":"1000","sort_by":"id","order_by":"asc","school_id":id}).then((resposnse)=>{
            
            if(resposnse.data.code==1){
                setClasslList(resposnse.data.data.list);
                geDevisionListApi(id,resposnse.data.data.list)
            }else{
                setClasslList([])
                geDevisionListApi(id,[])
            }
        })
    }

    const geDevisionListApi = (id,classListData)=>{
        getDevisionList({"page":"1","limit":"1000","sort_by":"id","order_by":"asc","school_id":id}).then((resposnse)=>{
            if(resposnse.data.code==1){
                setDevisionList(resposnse.data.data.list);
                setDataGroup(classListData,resposnse.data.data.list)
            }else{
                setDevisionList([]);
                setDataGroup([],[])
            }
        })
    }

    const setDataGroup = (classListData,devisionListData)=>{
        let groupData=[]
        classListData.forEach( (item)=>{
            
            let options=[]
            devisionListData.filter( devision => devision.class_id===item.id).forEach((subItem)=>{
                options.push({label:subItem.name,value:item.id+","+subItem.id})
            })

            if(options.length>0){
                var data={label:item.name,options:options}
                groupData.push(data) 
            }
        })

        if(groupData.length>0){
            var selectData=groupData[0].options[0]
            setClassAndDevisionList(groupData)
            setClassDevisionOption(selectData)
        }else{
            setClassAndDevisionList(groupData)
            setClassDevisionOption(null)
        }

        if(isEditFirstTimeSetDataClassDiviosn==="a"){
            console.log("Again not call Devison")
            isEditFirstTimeSetDataClassDiviosn="a"
            let selectData = []
            groupData.forEach((item)=>{
                item.options.forEach((subItem)=>{
                    console.log(subItem.value)
                    console.log((tempStudentDetails.class_id+","+tempStudentDetails.division_id))
                    if(subItem.value===(tempStudentDetails.class_id+","+tempStudentDetails.division_id)){
                        selectData.push(subItem)
                    }
                })
            })
            console.log(selectData)
            setClassDevisionOption(selectData[0])
        }

        props.isLoader(false)
    }

    const getSchoolListApi = ()=>{
        getSchoolList({"page": "1", "limit": "1000", "sort_by": "id", "order_by": "asc"})
        .then((resposnse) => {
            if (resposnse.data.code == 1) {
                let list =[]
                resposnse.data.data.list.map((item, index) => {
                    console.log({label:item.name,value:item.id})
                    list.push({label:item.name,value:item.id})
                })
                setSchoolList(list)
                setSelectedOption(list[0])

                if(isEditFirstTimeSetDataSchool=="a"){
                    geClassListApi(tempStudentDetails.school_id)
                }else{
                    geClassListApi(list[0].value)
                }

                if(isEditFirstTimeSetDataSchool==="a"){
                    console.log("Again not call")
                    isEditFirstTimeSetDataSchool=""
                    let selectData =list.filter((item)=> {return item.value === tempStudentDetails.school_id})
                    console.log(selectData)
                    setSelectedOption(selectData[0])
                }
            } else {
                navigate(-1)
            }
        });
    }

    useEffect(() => {
        if (props.type == "e") {
            console.log("Edit")
            props.isLoader(true)
            getUserDetails({"user_id":id.userId}).then((resposnse)=>{
                console.log(resposnse)
                if(resposnse.data.code==1){
                     let data= {name: resposnse.data.data.name, aboutMe: resposnse.data.data.about_me, userName: resposnse.data.data.username, email: resposnse.data.data.email, password: resposnse.data.data.password, phoneNumber: resposnse.data.data.phone_number}
                    setFormValues(data);
                    setStudentDetails(resposnse.data.data)
                    tempStudentDetails=resposnse.data.data
                    setPasswordOld(resposnse.data.data.password)
                    isEditFirstTimeSetDataSchool="a"
                    isEditFirstTimeSetDataClassDiviosn="a"
                    getSchoolListApi()
                }else{
                    navigate(-1)
                }
          })   
        }else{
            props.isLoader(true)
            getSchoolListApi()
        }
    }, []);

    return (
        <section className="content">
            <div className="container-fluid">
                <div className="row clearfix ">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="card m-t-15">
                            <div className="header">
                                <h2>{(props.type == "e") ? "Edit Student" : "Create Student"}</h2>
                            </div>
                            <div className="body">
                                <form id="form_validation" method="" onSubmit={clickSubmitButton} action="#">
                                    <label htmlFor="name">Name</label>
                                    <div className="form-group">
                                        <div className="form-line">
                                            <input type="text" placeholder='Name' className="form-control" id="name"
                                                   name="name" required value={formValues.name}
                                                   onChange={handleChange}/>
                                        </div>
                                    </div>
                                    <label htmlFor="about_me">About Me</label>
                                    <div className="form-group">
                                        <div className="form-line">
                                            <textarea placeholder='About Me' cols="30" rows="5"
                                                      className="form-control no-resize" id="aboutMe" name="aboutMe"
                                                      required value={formValues.aboutMe}
                                                      onChange={handleChange}></textarea>
                                        </div>
                                    </div>
                                    <label htmlFor="user_name">Username</label>
                                    <div className="form-group">
                                        <div className="form-line">
                                            <input type="text" placeholder='Username' className="form-control"
                                                   id="userName" name="userName" required value={formValues.userName}
                                                   onChange={handleChange}/>
                                        </div>
                                    </div>
                                    <label htmlFor="email">Email</label>
                                    <div className="form-group">
                                        <div className="form-line">
                                            <input type="email" placeholder='Email' className="form-control" id="email"
                                                   name="email" required value={formValues.email}
                                                   onChange={handleChange}/>
                                        </div>
                                    </div>
                                    <label htmlFor="password">Password</label>
                                    <div className="form-group">
                                        <div className="form-line">
                                            <input type="password" placeholder='Password' className="form-control"
                                                   id="password" name="password" required value={formValues.password}
                                                   onChange={handleChangePassword}/>
                                        </div>
                                    </div>
                                    <label htmlFor="phone_number">Phone Number</label>
                                    <div className="form-group">
                                        <div className="form-line">
                                            <input type="tel" placeholder='Phone Number' className="form-control"
                                                   id="phoneNumber" name="phoneNumber" required
                                                   value={formValues.phoneNumber} onChange={handleChange}/>
                                        </div>
                                    </div>

                                    <label htmlFor="name_en">Select School</label>
                                    <div className="form-group">
                                    <Select
                                                    value={selectedOptionSchool}
                                                    onChange={handleSchoolChange}
                                                    options={schoolList}
                                                    name="selectSchoolList"
                                                 />
                                      <input name="school" className='dropHideInput' value={ (selectedOptionSchool == null)? "" : ""+selectedOptionSchool.value  } id="school" type="text" required/>            

                                    </div>

                                    <label htmlFor="name_en">Select Class And Devision</label>
                                    <div className="form-group">
                                    <Select
                                                    value={selecteClassDevisiondOptionSchool}
                                                    onChange={handleClassDiviosnChange}
                                                    options={classAndDevisionList}
                                                    name="selectClassAndDevisionList"
                                                 />
                                     <input name="classAndDevision"  className='dropHideInput' value={ (selecteClassDevisiondOptionSchool == null)? "" : ""+selecteClassDevisiondOptionSchool.value  } id="classAndDevision" type="text" required/>            

                                    </div>

                                    <button className="btn btn-raised btn-primary waves-effect" type="submit">SUBMIT
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}