import React, { useState ,useEffect} from 'react'
//Datatable Modules
import 'jquery/dist/jquery.min.js'
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import $ from 'jquery'; 
import Heading from '../../components/Heading/Heading';
import { Link,useNavigate,useParams } from 'react-router-dom'
import {getUserList,deleteUser,updateUserStatus} from '../../api/apiHandler'
import Alert from '../../components/Alert/Alert';

export default function UserListingTeacher(props) {

    const [myTeachertist, setTeacherList] = useState(null)

    useEffect(() => {
        let mounted = true;
        props.isLoader(true)
        getUserList({"user_type":"class_teacher","page":"1","limit":"1000","sort_by":"id","order_by":"desc"}).then((resposnse)=>{
            console.log(resposnse)
            if(resposnse.data.code==1){
                setTeacherList(resposnse.data.data.list);
            }else{
                setTeacherList([]);
            }
        })
        return () => mounted = false;
    }, []);

    let navigate = useNavigate(); 

    const redirectCreateTeacher = ()=>{
        let path ="/creatClassTeacher"
        navigate(path)
    }

    $(document).ready(function () {
        $('#mangeUser').DataTable(
            {
                pagingType: 'full_numbers',
                  pageLength: 10,
                  order: [[0, 'desc']],
                  processing: true,
                  dom: 'Bfrtip',
                      buttons: ['copy', 'csv', 'print'
                      ],
                      "bDestroy": true
            }
        );
    });


    function deleteUserIdWise(userId){
        console.log("Call Delete"+userId)
        if(window.confirm("Are you sure you want to delete?")){
            props.isLoader(true)
            deleteUser({"user_id":userId}).then((resposnse)=>{
                console.log(resposnse);
                if(resposnse.data.code==1){
                    $('#mangeUser').DataTable().row("#userId"+userId).remove().draw();
                }else{
                }
                props.isLoader(false)
            });
        }
    }
    
    const statusChangeListner = (position)=>{

        console.log(position) 
 
        props.isLoader(true)
 
        console.log("Call api change status")
 
        let apiData={}
        if(myTeachertist[position].is_active===1){
             apiData={"user_id":myTeachertist[position].id,"status":0}        
        }
        else{
             apiData={"user_id":myTeachertist[position].id,"status":1}        
        }
 
        console.log(apiData)
 
        updateUserStatus(apiData).then((resposnse)=>{
         console.log(resposnse)
         props.isLoader(false)
         if(resposnse.data.code==1){
             callAfterApiChangeStatus(position,true)
         }else{
             callAfterApiChangeStatus(position,false)
         }
         })    
     }
 
     const callAfterApiChangeStatus = (position,status)=>{
 
         if(status){
 
             if(myTeachertist[position].is_active===1){
                myTeachertist[position].is_active=0       
                }else{
                    myTeachertist[position].is_active=1
                 }
         }
 
         setTeacherList(myTeachertist)
 
         manageCheckStatus(position)
 
     }
 
     const manageCheckStatus = (position)=>{
         console.log(position) 
  
        if(myTeachertist[position].is_active===1){
         console.log("set Check")
         $("#" + 'status'+position).attr("checked", true);        
        }else{
         console.log("Set Uncheck")
         $("#" + 'status'+position).attr("checked", false);        
         }
     }

    

    if (myTeachertist==null) return null;
    props.isLoader(false)

    return (
    <section className="content home">
        <Heading title="Class Teacher"/>
        <div className="container-fluid"> 
        <div className="row clearfix">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card">
                    <div className="header">
                    <button type="button" className="btn btn-raised btn-primary m-t-15 m-b-15 waves-effect right" onClick={redirectCreateTeacher}>Create</button>
                    </div>
                    <div className="body">
                        <table id="mangeUser" className="table table-bordered table-striped table-hover dataTable js-exportable">
                            <thead>
                                <tr>
                                    <th>Created Date</th>
                                    <th>Username</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>School Name</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th>Created Date</th>
                                    <th>Username</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>School Name</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                            <tbody>
                            { 
                            myTeachertist && myTeachertist.map((item,index)=>{
                                    return(
                                        <tr id={"userId"+item.id}>
                                            <td>{item.created_at}</td>
                                            <td>{item.username}</td>
                                            <td>{item.name}</td>
                                            <td>{item.email}</td>
                                            <td><Link to={`/SchoolDetails/${item.school_id}`} className="col-green"><u >{item.school_name}</u></Link></td>
                                            <td>
                                                <div className="switch">
                                                    <label>
                                                        <input type="checkbox" id={"status"+index} onClick={()=>statusChangeListner(index)}/>
                                                        <span className="lever switch-col-blue" ></span>
                                                    </label>
                                                </div>  
                                                {manageCheckStatus(index)}
                                           </td>
                                            <td className="text-right">
                                            <div className='action-btn'> 
                                            <Link to={`/classTeacherDetails/${item.id}`} className="btn btn-default col-green"><i className="zmdi zmdi-eye"></i></Link>                       
                                            <Link to={`/editClassTeacher/${item.id}`} className="btn btn-default col-green"><i className="zmdi zmdi-edit"></i></Link>
                                            <a onClick={()=>deleteUserIdWise(item.id)} className="btn btn-default col-red"><i className="zmdi zmdi-delete"></i></a>
                                            </div>    
                                            </td>
                                        </tr>
                                        )
                                })}
                        </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </div>
        </div>
    </section>
    );
}
