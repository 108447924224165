import React, { useState ,useEffect} from 'react'
import { Link,useNavigate,useParams } from 'react-router-dom'
import {getUserDetails} from '../../api/apiHandler'
import Alert from '../../components/Alert/Alert';


export default function TeacherDetails(props) {

    let navigate = useNavigate(); 

    let { userId } = useParams();


    const [studentDetails, setStudentDetails] = useState(null)

    useEffect(() => {
        let mounted = true;
        props.isLoader(true)
        getUserDetails({"user_id":userId}).then((resposnse)=>{
            console.log(resposnse)
            if(resposnse.data.code==1){
                setStudentDetails(resposnse.data.data)
            }else{
                navigate(-1)
            }
      })
      return () => mounted = false;
    }, []);

    if (studentDetails==null ) return null;

    props.isLoader(false)

    return (
        <section className="content home">   
        <div className="container-fluid"> 
            <div className="row clearfix">
                <div className="col-lg-12 col-md-12 col-sm-12 m-t-15">
                    <div className="card">
                        <div className="header">
                        <h2> { (props.role==="class_teacher") ? "Class Teacher Details": "Teacher Details"}</h2>
                        </div>
                        <div className="body">
                        <div className="tab-content">
                        <div role="tabpanel" className="tab-pane active" id="official" aria-expanded="true">
                            <li className="list-group-item"><b className="mr-2">User Id : </b> {studentDetails.id}</li>
                            <li className="list-group-item"><b className="mr-2">Name : </b> {studentDetails.name}</li>
                            <li className="list-group-item"><b className="mr-2">About Me : </b> {studentDetails.about_me}</li>
                            <li className="list-group-item"><b className="mr-2">Username : </b> {studentDetails.username}</li>
                            <li className="list-group-item"><b className="mr-2">Email : </b> {studentDetails.email}</li>
                            <li className="list-group-item"><b className="mr-2">Phone Number : </b> {studentDetails.phone_number}</li>

                            <li className="list-group-item"><b className="mr-2">School Name : </b> {studentDetails.school_name}</li>
                            <li className="list-group-item"><b className="mr-2">School Id : </b> {studentDetails.school_id}</li>

                            <li className="list-group-item"><b className="mr-2">Create Date : </b> {studentDetails.created_at}</li>
                            <li className="list-group-item"><b className="mr-2">Status : </b> {(studentDetails.is_active==1)?"Active":"Inactive"}</li>
                        </div>
                        </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card">                    
                    <div className="header">
                        <h2>Assign Class And Division</h2>
                    </div>
                    <div className="body">
                        <table id="mangeAssignSchool" className="table table-bordered table-striped table-hover dataTable js-exportable">
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Class</th>
                                    <th>Division</th>
                                    <th>Role</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                <th>Id</th>
                                    <th>Class</th>
                                    <th>Division</th>
                                    <th>Role</th>
                                </tr>
                            </tfoot>
                            <tbody id="devisionId">
                            { 
                            studentDetails.class_division_list && studentDetails.class_division_list.map((item)=>{
                                    return(
                                      <tr>
                                            <td>{item.id}</td>
                                            <td>{item.class}</td>
                                            <td>{item.division}</td>
                                            <td>{item.role}</td>
                                      </tr>    
                                    )
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
                </div>

            </div>
        </div>
     </section>
    );
}