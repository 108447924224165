import React, { useState ,useEffect} from 'react'
//Datatable Modules
import 'jquery/dist/jquery.min.js'
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import $ from 'jquery'; 
import Heading from '../../components/Heading/Heading';
import { Link,useNavigate,useParams } from 'react-router-dom'
import {getUserList} from '../../api/apiHandler'

export default function PerformanceScore(props) {
    const [myStudentist, setStudentList] = useState(null)

    useEffect(() => {
        let mounted = true;
        props.isLoader(true)
        getUserList({"user_type":"student","page":"1","limit":"1000000","sort_by":"id","order_by":"desc"}).then((resposnse)=>{
            console.log(resposnse)
            if(resposnse.data.code==1){
                setStudentList(resposnse.data.data.list);
            }else{
                setStudentList([]);
            }
        })
        return () => mounted = false;
    }, []);

    let navigate = useNavigate(); 

    const redirectCreateStudent = ()=>{
        let path ="/createStudent"
        navigate(path)
    }

    $(document).ready(function () {
        $('#mangeUser').DataTable(
            {
                pagingType: 'full_numbers',
                  pageLength: 10,
                  processing: true,
                  order: [[5, 'desc']],
                  dom: 'Bfrtip',
                      buttons: ['copy', 'csv', 'print'
                      ],
                      "bDestroy": true
            }
        );
    });

    if (myStudentist==null) return null;
    props.isLoader(false)

    return (
    <section className="content home">
        <Heading title="Performance Score"/>
        <div className="container-fluid"> 
        <div className="row clearfix">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card">
                    <div className="header">
                    <button type="button" className="btn btn-raised btn-primary m-t-15 m-b-15 waves-effect right" onClick={redirectCreateStudent}>Create</button>
                    </div>
                    <div className="body">
                        <table id="mangeUser" className="table table-bordered table-striped table-hover dataTable js-exportable">
                            <thead>
                                <tr>
                                    <th>Created Date</th>
                                    <th>Username</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>School Name</th>
                                    <th>Score</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th>Created Date</th>
                                    <th>Username</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>School Name</th>
                                    <th>Score</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                            <tbody>
                            { 
                            myStudentist && myStudentist.map((item,index)=>{
                                    return(
                                        <tr id={"userId"+item.id}>
                                            <td>{item.created_at}</td>
                                            <td><Link to={`/studentDetails/${item.id}`} className="col-green"><u >{item.username}</u></Link></td>
                                            <td>{item.name}</td>
                                            <td>{item.email}</td>
                                            <td><Link to={`/SchoolDetails/${item.school_id}`} className="col-green"><u >{item.school_name}</u></Link></td>
                                            <td>{item.score}</td>
                                           <td className="text-right">
                                            <div className='action-btn'>                        
                                            <Link to={`/PerformanceStudentDetails/${item.id}`} className="btn btn-default col-green"><i className="zmdi zmdi-eye"></i></Link>
                                            </div>    
                                            </td>
                                        </tr>

                                        )
                                })}
                        </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </div>
        </div>
    </section>
    );

}
