import React from 'react'
//Datatable Modules
import 'jquery/dist/jquery.min.js'
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import $ from 'jquery'; 
import Heading from '../../components/Heading/Heading';

export default function UserListingSchoolAdmin() {
    $(document).ready(function () {
        $('#mangeUser').DataTable(
            {
                pagingType: 'full_numbers',
                  pageLength: 10,
                  processing: true,
                  dom: 'Bfrtip',
                      buttons: ['copy', 'csv', 'print'
                      ],
                      "bDestroy": true
            }
        );
    });
    return (
    <section className="content home">
        <Heading title="School Admin"/>
        <div className="container-fluid"> 
        <div className="row clearfix">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card">
                    <div className="header">
                    <button type="button" class="btn btn-raised btn-primary m-t-15 m-b-15 waves-effect right">Create</button>
                    </div>
                    <div className="body">
                        <table id="mangeUser" className="table table-bordered table-striped table-hover dataTable js-exportable">
                            <thead>
                                <tr>
                                    <th>Created Date</th>
                                    <th>Name</th>
                                    <th>Username</th>
                                    <th>Role</th>
                                    <th>Email Id</th>
                                    <th>Status</th>
                                    <th>Account</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th>Created Date</th>
                                    <th>Name</th>
                                    <th>Username</th>
                                    <th>Role</th>
                                    <th>Email Id</th>
                                    <th>Status</th>
                                    <th>Account</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                            <tbody>
                                <tr>
                                    <td>25/2/22 </td>
                                    <td>John Test</td>
                                    <td>John796</td>
                                    <td>SubAdmin</td>
                                    <td>john@gmail.com</td>
                                    <td>Active</td>
                                    <td>Unfreeze</td>
                                    <td class="text-right">
                                    <div className='action-btn'>                        
                                       <a href={void(0)} class="btn btn-default col-green"><i class="zmdi zmdi-eye"></i></a>
                                       <a href={void(0)} class="btn btn-default col-green"><i class="zmdi zmdi-edit"></i></a>
                                       <a href={void(0)} class="btn btn-default col-red"><i class="zmdi zmdi-delete"></i></a>
                                    </div>    
                                    </td>
                                </tr>
                                <tr>
                                    <td>25/2/22 </td>
                                    <td>John Test</td>
                                    <td>John796</td>
                                    <td>SubAdmin</td>
                                    <td>john@gmail.com</td>
                                    <td>Active</td>
                                    <td>Unfreeze</td>
                                    <td class="text-right">
                                    <div className='action-btn'>                        
                                       <a href={void(0)} class="btn btn-default col-green"><i class="zmdi zmdi-eye"></i></a>
                                       <a href={void(0)} class="btn btn-default col-green"><i class="zmdi zmdi-edit"></i></a>
                                       <a href={void(0)} class="btn btn-default col-red"><i class="zmdi zmdi-delete"></i></a>
                                    </div>    
                                    </td>
                                </tr>
                                <tr>
                                    <td>25/2/22 </td>
                                    <td>John Test</td>
                                    <td>John796</td>
                                    <td>SubAdmin</td>
                                    <td>john@gmail.com</td>
                                    <td>Active</td>
                                    <td>Unfreeze</td>
                                    <td class="text-right">
                                    <div className='action-btn'>                        
                                       <a href={void(0)} class="btn btn-default col-green"><i class="zmdi zmdi-eye"></i></a>
                                       <a href={void(0)} class="btn btn-default col-green"><i class="zmdi zmdi-edit"></i></a>
                                       <a href={void(0)} class="btn btn-default col-red"><i class="zmdi zmdi-delete"></i></a>
                                    </div>    
                                    </td>
                                </tr>
                                <tr>
                                    <td>25/2/22 </td>
                                    <td>John Test</td>
                                    <td>John796</td>
                                    <td>SubAdmin</td>
                                    <td>john@gmail.com</td>
                                    <td>Active</td>
                                    <td>Unfreeze</td>
                                    <td class="text-right">
                                    <div className='action-btn'>                        
                                       <a href={void(0)} class="btn btn-default col-green"><i class="zmdi zmdi-eye"></i></a>
                                       <a href={void(0)} class="btn btn-default col-green"><i class="zmdi zmdi-edit"></i></a>
                                       <a href={void(0)} class="btn btn-default col-red"><i class="zmdi zmdi-delete"></i></a>
                                    </div>    
                                    </td>
                                </tr>
                                <tr>
                                    <td>25/2/22 </td>
                                    <td>John Test</td>
                                    <td>John796</td>
                                    <td>SubAdmin</td>
                                    <td>john@gmail.com</td>
                                    <td>Active</td>
                                    <td>Unfreeze</td>
                                    <td class="text-right">
                                    <div className='action-btn'>                        
                                       <a href={void(0)} class="btn btn-default col-green"><i class="zmdi zmdi-eye"></i></a>
                                       <a href={void(0)} class="btn btn-default col-green"><i class="zmdi zmdi-edit"></i></a>
                                       <a href={void(0)} class="btn btn-default col-red"><i class="zmdi zmdi-delete"></i></a>
                                    </div>    
                                    </td>
                                </tr>
                                <tr>
                                    <td>25/2/22 </td>
                                    <td>John Test</td>
                                    <td>John796</td>
                                    <td>SubAdmin</td>
                                    <td>john@gmail.com</td>
                                    <td>Active</td>
                                    <td>Unfreeze</td>
                                    <td class="text-right">
                                    <div className='action-btn'>                        
                                       <a href={void(0)} class="btn btn-default col-green"><i class="zmdi zmdi-eye"></i></a>
                                       <a href={void(0)} class="btn btn-default col-green"><i class="zmdi zmdi-edit"></i></a>
                                       <a href={void(0)} class="btn btn-default col-red"><i class="zmdi zmdi-delete"></i></a>
                                    </div>    
                                    </td>
                                </tr>
                                <tr>
                                    <td>25/2/22 </td>
                                    <td>John Test</td>
                                    <td>John796</td>
                                    <td>SubAdmin</td>
                                    <td>john@gmail.com</td>
                                    <td>Active</td>
                                    <td>Unfreeze</td>
                                    <td class="text-right">
                                    <div className='action-btn'>                        
                                       <a href={void(0)} class="btn btn-default col-green"><i class="zmdi zmdi-eye"></i></a>
                                       <a href={void(0)} class="btn btn-default col-green"><i class="zmdi zmdi-edit"></i></a>
                                       <a href={void(0)} class="btn btn-default col-red"><i class="zmdi zmdi-delete"></i></a>
                                    </div>    
                                    </td>
                                </tr>
                                <tr>
                                    <td>25/2/22 </td>
                                    <td>John Test</td>
                                    <td>John796</td>
                                    <td>SubAdmin</td>
                                    <td>john@gmail.com</td>
                                    <td>Active</td>
                                    <td>Unfreeze</td>
                                    <td class="text-right">
                                    <div className='action-btn'>                        
                                       <a href={void(0)} class="btn btn-default col-green"><i class="zmdi zmdi-eye"></i></a>
                                       <a href={void(0)} class="btn btn-default col-green"><i class="zmdi zmdi-edit"></i></a>
                                       <a href={void(0)} class="btn btn-default col-red"><i class="zmdi zmdi-delete"></i></a>
                                    </div>    
                                    </td>
                                </tr>
                                <tr>
                                    <td>25/2/22 </td>
                                    <td>John Test</td>
                                    <td>John796</td>
                                    <td>SubAdmin</td>
                                    <td>john@gmail.com</td>
                                    <td>Active</td>
                                    <td>Unfreeze</td>
                                    <td class="text-right">
                                    <div className='action-btn'>                        
                                       <a href={void(0)} class="btn btn-default col-green"><i class="zmdi zmdi-eye"></i></a>
                                       <a href={void(0)} class="btn btn-default col-green"><i class="zmdi zmdi-edit"></i></a>
                                       <a href={void(0)} class="btn btn-default col-red"><i class="zmdi zmdi-delete"></i></a>
                                    </div>    
                                    </td>
                                </tr>
                                <tr>
                                    <td>25/2/22 </td>
                                    <td>John Test</td>
                                    <td>John796</td>
                                    <td>SubAdmin</td>
                                    <td>john@gmail.com</td>
                                    <td>Active</td>
                                    <td>Unfreeze</td>
                                    <td class="text-right">
                                    <div className='action-btn'>                        
                                       <a href={void(0)} class="btn btn-default col-green"><i class="zmdi zmdi-eye"></i></a>
                                       <a href={void(0)} class="btn btn-default col-green"><i class="zmdi zmdi-edit"></i></a>
                                       <a href={void(0)} class="btn btn-default col-red"><i class="zmdi zmdi-delete"></i></a>
                                    </div>    
                                    </td>
                                </tr>
                                <tr>
                                    <td>25/2/22 </td>
                                    <td>John Test</td>
                                    <td>John796</td>
                                    <td>SubAdmin</td>
                                    <td>john@gmail.com</td>
                                    <td>Active</td>
                                    <td>Unfreeze</td>
                                    <td class="text-right">
                                    <div className='action-btn'>                        
                                       <a href={void(0)} class="btn btn-default col-green"><i class="zmdi zmdi-eye"></i></a>
                                       <a href={void(0)} class="btn btn-default col-green"><i class="zmdi zmdi-edit"></i></a>
                                       <a href={void(0)} class="btn btn-default col-red"><i class="zmdi zmdi-delete"></i></a>
                                    </div>    
                                    </td>
                                </tr>
                                <tr>
                                    <td>25/2/22 </td>
                                    <td>John Test</td>
                                    <td>John796</td>
                                    <td>SubAdmin</td>
                                    <td>john@gmail.com</td>
                                    <td>Active</td>
                                    <td>Unfreeze</td>
                                    <td class="text-right">
                                    <div className='action-btn'>                        
                                       <a href={void(0)} class="btn btn-default col-green"><i class="zmdi zmdi-eye"></i></a>
                                       <a href={void(0)} class="btn btn-default col-green"><i class="zmdi zmdi-edit"></i></a>
                                       <a href={void(0)} class="btn btn-default col-red"><i class="zmdi zmdi-delete"></i></a>
                                    </div>    
                                    </td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </section>
    );
}
