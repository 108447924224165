import './App.css';
import NavBar from './components/NavBar/NavBar';
import Loader from './components/Loding/Loder';

import Dashboard from './pages/Dashboard/Dashboard';

import UserListingStudent from './pages/UserListing/UserListingStudent';
import UserListingTeacher from './pages/UserListing/UserListingTeacher';
import UserListingSchoolAdmin from './pages/UserListing/UserListingSchoolAdmin';
import UserListingClassTeacher from './pages/UserListing/UserListingClassTeacher';

import SchoolManagementListing from './pages/SchoolManagement/SchoolManagementListing';
import SchoolDetails from './pages/SchoolManagement/SchoolDetails';
import BulkCreate from './pages/SchoolManagement/BulkCreate';
import CrteateSchool from './pages/SchoolManagement/CrteateSchool';
import CrteateClass from './pages/SchoolManagement/CrteateClass';
import CrteateDevision from './pages/SchoolManagement/CrteateDevision';
import DivisionDetails from './pages/SchoolManagement/DivisionDetails';

import { Route, Routes } from "react-router-dom";
import { useState } from 'react';
import StudentDetails from './pages/UserListing/StudentDetails';
import CreateStudent from './pages/UserListing/CreateStudent';
import CreateStudentBulk from './pages/UserListing/CreateStudentBulk';

import TeacherDetails from './pages/UserListing/TeacherDetails';
import CreateTeacher from './pages/UserListing/CreateTeacher';

import SignIn from './pages/Auth/SignIn';
import RedirectBlankPage from './pages/Common/RedirectBlankPage';
import RedirectBlankPageHome from './pages/Common/RedirectBlankPageHome';
import AdminListing from './pages/AdminManage/AdminListing';
import CreateAdmin from './pages/AdminManage/CreateAdmin';
import AdminDetails from './pages/AdminManage/AdminDetails';

import FunZoneListing from './pages/FunZone/FunZoneListing';

import BlockWordListing from './pages/BlockWord/BlockWordListing';
import CreateBlockWord from './pages/BlockWord/CreateBlockWord';

import AvatarListing from './pages/Avatar/AvatarListing';
import CreateAvatar from './pages/Avatar/CreateAvatar';
import FunZoneCreate from './pages/FunZone/FunZoneCreate';
import FunZoneDetails from './pages/FunZone/FunZoneDetails';
import StaticPages from'./pages/StaticPages/StaticPages';

import FAQListing from'./pages/StaticPages/FAQListing';
import FAQSDetails from'./pages/StaticPages/FAQSDetails';
import FAQCreate from './pages/StaticPages/FAQCreate';
import NotificationCreate from './pages/Notifications/NotificationCreate';
import NotificationListing from './pages/Notifications/NotificationListing';

import PredefinedClassListing from './pages/PredefinedClass/PredefinedClassListing'
import CrteateClassCommon from './pages/PredefinedClass/CrteateClassCommon'

import PerformanceScore from './pages/performancescore/PerformanceScore'
import PerformanceStudentDetails from './pages/performancescore/PerformanceStudentDetails'
import ChangePassword from './pages/ChangePassword/ChangePassword';
import CreateSubAdmin from './pages/AdminManage/CreateSubAdmin';
import WBDataDownload from './pages/WBData/Download';

import SyncDb from './pages/SyncDb';

function App() {
  const [isLoading, setIsLoading] = useState(false);

  const showLoder =(isLoading)=>{
    setIsLoading(isLoading)
}

const getFlow = ()=>{

  if(!localStorage.getItem("isLogin",false)){
    return (
      <>
     <Loader isActive={isLoading}/>
    <Routes>
    <Route path="/signIn" element={<SignIn type="e" isLoader={showLoder}/>}/>
    <Route path="*" element={<RedirectBlankPage />}/>
    </Routes>
      </>
    )
  }
  else{
    return (
      <>
     <Loader isActive={isLoading}/>
    <NavBar />
    <Routes>
    <Route path="/" element={<Dashboard isLoader={showLoder}/>} />

    <Route path="/usersListingStudent" element={<UserListingStudent isLoader={showLoder}/>}/>
    <Route path="/studentDetails/:userId" element={<StudentDetails isLoader={showLoder}/>} />
    <Route path="/createStudent" element={<CreateStudent type="c" isLoader={showLoder}/>} />
    <Route path='/createStudentBulk/:schoolId' element={<CreateStudentBulk type="c" isLoader={showLoder}/>} />
    <Route path='/bulkCreateStudent/:schoolId' element={<BulkCreate type="student" isLoader={showLoder}/>} />
    <Route path="/editStudent/:userId" element={<CreateStudent type="e" isLoader={showLoder}/>} />
    
    <Route path="/usersListingTeacher" element={<UserListingTeacher  isLoader={showLoder}/>}/>
    <Route path="/teacherDetails/:userId" element={<TeacherDetails isLoader={showLoder} role="teacher"/>} />
    <Route path="/createTeacher/:schoolId" element={<CreateTeacher role="teacher" type="c" isLoader={showLoder}/>} />
    <Route path="/bulkCreateTeacher/:schoolId" element={<BulkCreate type="teacher" isLoader={showLoder}/>} />
    <Route path="/editTeacher/:userId" element={<CreateTeacher role="teacher" type="e" isLoader={showLoder}/>} />

    <Route path="/UserListingClassTeacher" element={<UserListingClassTeacher isLoader={showLoder} />}/>
    <Route path="/classTeacherDetails/:userId" element={<TeacherDetails isLoader={showLoder} role="class_teacher"/>} />
    <Route path="/creatClassTeacher" element={<CreateTeacher role="class_teacher" type="c" isLoader={showLoder}/>} />
    <Route path="/editClassTeacher/:userId" element={<CreateTeacher role="class_teacher" type="e" isLoader={showLoder}/>} />


    <Route path="/usersListingSchoolAdmin" element={<UserListingSchoolAdmin />}/>
    <Route path="/SchoolManagementListing" element={<SchoolManagementListing isLoader={showLoder}/>}/>
    <Route path="/SchoolDetails/:schoolId" element={<SchoolDetails  isLoader={showLoder}/>}/>
    <Route path="/createSchool" element={<CrteateSchool type="c" isLoader={showLoder}/>}/>
    <Route path="/bulkCreateSchool" element={<BulkCreate type="school" isLoader={showLoder}/>}/>
    <Route path="/editSchool/:schoolId" element={<CrteateSchool type="e" isLoader={showLoder}/>}/>
    <Route path="/createClass/:schoolId" element={<CrteateClass type="c" isLoader={showLoder}/>}/>
    <Route path="/editClass/:classId" element={<CrteateClass type="e" isLoader={showLoder}/>}/>
    <Route path="/createDivision/:schoolId" element={<CrteateDevision type="c" isLoader={showLoder}/>}/>
    <Route path="/bulkCreateDivision/:schoolId" element={<BulkCreate type="division" isLoader={showLoder}/>}/>
    <Route path="/editDivision/:schoolIdDivID" element={<CrteateDevision type="e" isLoader={showLoder}/>}/>
    <Route path="/divisionDetails/:divId" element={<DivisionDetails isLoader={showLoder}/>}/>


    <Route path="/adminManage" element={<AdminListing  isLoader={showLoder}/>}/>
    <Route path="/createAdmin" element={<CreateAdmin type="c" isLoader={showLoder}/>}/>
    <Route path="/createSubAdmin" element={<CreateSubAdmin type="c" isLoader={showLoder}/>}/>
    <Route path="/editAdmin/:userId" element={<CreateAdmin type="e" isLoader={showLoder}/>}/>
    <Route path="/editSubAdmin/:userId" element={<CreateSubAdmin type="e" isLoader={showLoder}/>}/>
    <Route path="/adminDetails/:userId" element={<AdminDetails isLoader={showLoder}/>}/>

    <Route path="/funZoneManage" element={<FunZoneListing  isLoader={showLoder}/>}/>
    <Route path="/createFunZone" element={<FunZoneCreate type="c" isLoader={showLoder}/>}/>
    <Route path="/editFunZone/:funId" element={<FunZoneCreate type="e" isLoader={showLoder}/>}/>
    <Route path="/funZoneDetails/:funId" element={<FunZoneDetails type="e" isLoader={showLoder}/>}/>

    <Route path="/blockWordListing" element={<BlockWordListing isLoader={showLoder}/>}/>
    <Route path="/createBlockWord" element={<CreateBlockWord isLoader={showLoder}/>}/>

    <Route path="/avatarListing" element={<AvatarListing isLoader={showLoder}/>}/>
    <Route path="/createAvatar" element={<CreateAvatar isLoader={showLoder}/>}/>
    <Route path="/editAvatar/:avatarId" element={<CreateAvatar type="e" isLoader={showLoder}/>}/>
    
    <Route path="/managePredefinedClassListing" element={<PredefinedClassListing isLoader={showLoder}/>}/>
    <Route path="/createClassCommon" element={<CrteateClassCommon isLoader={showLoder}/>}/>
    <Route path="/editClassCommon/:classID" element={<CrteateClassCommon type="e" isLoader={showLoder}/>}/>  

    <Route path='/PrivacyPolicyE' element={<StaticPages key={1} title="Privacy &amp; Policy English"  type="privacy_policy" lng="en" isLoader={showLoder}/>}/>  
    <Route path='/PrivacyPolicyA' element={<StaticPages key={2} title="Privacy &amp; Policy Arabic"  type="privacy_policy" lng="ar" isLoader={showLoder}/>}/>  
    <Route path='/PrivacyPolicyT' element={<StaticPages key={3} title="Privacy &amp; Policy Turkish"  type="privacy_policy" lng="tu" isLoader={showLoder}/>}/>  

    <Route path='/AboutUsE' element={<StaticPages key={4} title="About Us English"  type="about_us" lng="en" isLoader={showLoder}/>}/>  
    <Route path='/AboutUsA' element={<StaticPages key={5} title="About Us Arabic"  type="about_us" lng="ar" isLoader={showLoder}/>}/>  
    <Route path='/AboutUsT' element={<StaticPages key={6} title="About Us Turkish"  type="about_us" lng="tu" isLoader={showLoder}/>}/>  

    <Route path='/TermsConditionsE' element={<StaticPages key={7} title="Terms &amp; Conditions English"  type="terms_conditions" lng="en" isLoader={showLoder}/>}/>  
    <Route path='/TermsConditionsA' element={<StaticPages key={8} title="Terms &amp; Conditions Arabic"  type="terms_conditions" lng="ar" isLoader={showLoder}/>}/>  
    <Route path='/TermsConditionsT' element={<StaticPages key={9} title="Terms &amp; Conditions Turkish"  type="terms_conditions" lng="tu" isLoader={showLoder}/>}/>  

    <Route path='/FAQListing' element={<FAQListing isLoader={showLoder}/>} />
    <Route path='/FAQDetails/:faqId' element={<FAQSDetails isLoader={showLoder}/>} />
    <Route path="/FAQCreate" element={<FAQCreate type="c" isLoader={showLoder}/>}/>
    <Route path="/FAQEdit/:faqId" element={<FAQCreate type="e" isLoader={showLoder}/>}/>

    <Route path="/NotificationListing" element={<NotificationListing  isLoader={showLoder}/>}/>
    <Route path="/NotificationCreate" element={<NotificationCreate  isLoader={showLoder}/>}/>

 
    <Route path="/PerformanceScoreManage" element={<PerformanceScore  isLoader={showLoder}/>}/>
    <Route path="/ChangePassword" element={<ChangePassword  isLoader={false}/>}/>
    <Route path="/PerformanceStudentDetails/:userId" element={<PerformanceStudentDetails isLoader={showLoder}/>} />
    <Route path="/WBDataDownload" element={<WBDataDownload isLoader={showLoder}/>} />
    <Route path='/ImportWBConvData' element={<BulkCreate type="wb_conv_data" isLoader={showLoder}/>} />

    <Route path='/SyncData' element={<SyncDb title="Sync Data" isLoader={showLoder}/>} />

    <Route path="*" element={<RedirectBlankPageHome />}/>
    </Routes>
      </>
    )
  }
}

  return (
    <>
    {getFlow()}
    </>
    );
}

export default App;
