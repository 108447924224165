import React, { useState ,useEffect} from 'react'
import { Link,useNavigate,useParams } from 'react-router-dom'
import {getNotificationList} from '../../api/apiHandler'
//Datatable Modules
import 'jquery/dist/jquery.min.js'
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import $ from 'jquery'; 
import Heading from '../../components/Heading/Heading';

export default function NotificationListing(props) {

    const [myClassList, setClasslList] = useState(null)

    useEffect(() => {
        let mounted = true;
        props.isLoader(true)
        getNotificationList({}).then((resposnse)=>{
            props.isLoader(false)
            console.log(resposnse)
            if(resposnse.data.code==1){
                setClasslList(resposnse.data.data);
            }else{
                setClasslList([]);
            }
        })
        return () => mounted = false;
    }, []);


    $(document).ready(function () {
        $('#mangeUser').DataTable(
            {
                pagingType: 'full_numbers',
                  pageLength: 10,
                  order: [[0, 'desc']],
                  processing: true,
                  dom: 'Bfrtip',
                  buttons: [],
                  searching: false,
                 "bDestroy": true
            }
        );
    });

    let navigate = useNavigate(); 
    
    const redirectCreateClass = ()=>{
        let path ="/NotificationCreate"
        navigate(path)
    }

    if (myClassList==null) return null;
    props.isLoader(false)

    return (
    <section className="content home">
        <Heading title="Notification"/>
        <div className="container-fluid"> 
        <div className="row clearfix">

        <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card">                    
                    <div className="header">
                        <button type="button" className="btn btn-raised btn-primary m-t-15 m-b-15 waves-effect right" onClick={redirectCreateClass}>Create</button>
                    </div>
                    <div className="body">
                        <table id="mangeUser" className="table table-bordered table-striped table-hover dataTable js-exportable">
                            <thead>
                                <tr>
                                    <th>Create Date</th>
                                    <th>Id</th>
                                    <th>Title</th>
                                    <th>Description</th>
                                    <th>SenderId</th>
                                    <th>SenderType</th>
                                    <th>ReceiverId</th>
                                    <th>ReceiverType</th>
                                    <th>Action</th>                                    
                                </tr>
                            </thead>
                            <tfoot>
                            <tr>
                                    <th>Create Date</th>
                                    <th>Id</th>
                                    <th>Title</th>
                                    <th>Description</th>
                                    <th>SenderId</th>
                                    <th>SenderType</th>
                                    <th>ReceiverId</th>
                                    <th>ReceiverType</th>
                                    <th>Action</th>                                    
                            </tr>
                            </tfoot>
                            <tbody id="classId">
                                { 
                                myClassList && myClassList.map((item)=>{
                                    return(
                                        <tr id={"classId"+item.id}>
                                        <td>{item.created_at}</td>
                                        <td>{item.id}</td>
                                        <td>{item.title}</td>
                                        <td>{item.message}</td>
                                        <td>{item.sender_id}</td>
                                        <td>{item.sender_type}</td>
                                        <td>{item.receiver_id}</td>
                                        <td>{item.receiver_type}</td>
                                        <td>{item.action}</td>

                                    </tr>    
                                    )
    
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </section>
    );
}
