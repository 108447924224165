import React, { useState ,useEffect} from 'react'
import { Link,useNavigate,useParams } from 'react-router-dom'
import {getFAQList,deleteFAQ,changeFAQStatus} from '../../api/apiHandler'
import Alert from '../../components/Alert/Alert';
//Datatable Modules
import 'jquery/dist/jquery.min.js'
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import $ from 'jquery'; 
import Heading from '../../components/Heading/Heading';

export default function FAQListing(props) {

    const [myFaqList, setFaqList] = useState(null)

    useEffect(() => {
        let mounted = true;
        props.isLoader(true)
        getFAQList({"page":"1","limit":"1000","sort_by":"id","order_by":"desc"}).then((resposnse)=>{
            console.log(resposnse)
            if(resposnse.data.code==1){
                setFaqList(resposnse.data.data.list);
            }else{
                setFaqList([]);
            }
        })
        return () => mounted = false;
    }, []);


    $(document).ready(function () {
        $('#mangeUser').DataTable(
            {
                pagingType: 'full_numbers',
                  pageLength: 10,
                  order: [[1, 'desc']],
                  processing: true,
                  dom: 'Bfrtip',
                      buttons: ['copy', 'csv', 'print'
                      ],
                      "bDestroy": true
            }
        );
    });

    let navigate = useNavigate(); 

    const redirectCreateAdmin = ()=>{
        let path ="/FAQCreate"
        navigate(path)
    }

    function deleteUserIdWise(faqId){
        console.log("Call Delete"+faqId)
        if(window.confirm("Are you sure you want to delete?")){
            props.isLoader(true)
            deleteFAQ({"faqs_id":faqId}).then((resposnse)=>{
                console.log(resposnse);
                if(resposnse.data.code==1){
                    $('#mangeFaq').DataTable().row("#faqId"+faqId).remove().draw();
                }else{
                }
                props.isLoader(false)
            });
        }
    }

    const statusChangeListner = (position)=>{

       console.log(position) 

       props.isLoader(true)

       console.log("Call api change status")

       let apiData={}
       if(myFaqList[position].is_active===1){
            apiData={"faqs_id":myFaqList[position].id,"status":0}        
       }
       else{
            apiData={"faqs_id":myFaqList[position].id,"status":1}        
       }

       console.log(apiData)

       changeFAQStatus(apiData).then((resposnse)=>{
        console.log(resposnse)
        props.isLoader(false)
        if(resposnse.data.code==1){
            callAfterApiChangeStatus(position,true)
        }else{
            callAfterApiChangeStatus(position,false)
        }
        })    
    }

    const callAfterApiChangeStatus = (position,status)=>{

        if(status){

            if(myFaqList[position].is_active===1){
                myFaqList[position].is_active=0       
               }else{
                myFaqList[position].is_active=1
                }
        }

        setFaqList(myFaqList)

        manageCheckStatus(position)

    }

    const manageCheckStatus = (position)=>{
        console.log(position) 
 
       if(myFaqList[position].is_active===1){
        $("#" + 'status'+position).attr("checked", true);        
       }else{
        $("#" + 'status'+position).attr("checked", false);        
        }
    }


    if (myFaqList==null) return null;
    props.isLoader(false)

    return (
    <section className="content home">
        <Heading title="Manage FAQ"/>
        <div className="container-fluid"> 
        <div className="row clearfix">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card">
                    <div className="header">
                    <button type="button" className="btn btn-raised btn-primary m-t-15 m-b-15 waves-effect right" onClick={redirectCreateAdmin}>Create</button>
                    </div>
                    <div className="body">
                        <table id="mangeFaq" className="table table-bordered table-striped table-hover dataTable js-exportable">
                            <thead>
                                <tr>
                                    <th>Created Date</th>
                                    <th>Id</th>
                                    <th>Question (EN)</th>
                                    <th>Answer (EN)</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th>Created Date</th>
                                    <th>Id</th>
                                    <th>Question (EN)</th>
                                    <th>Answer (EN)</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                            <tbody>
                            { 
                            myFaqList && myFaqList.map((item,index)=>{
                                    return(
                                        <tr id={"faqId"+item.id}>
                                            <td>{item.created_at}</td>
                                            <td>{item.id}</td>
                                            <td>{item.en_question}</td>
                                            <td>{item.en_answer}</td>
                                            <td>
                                                <div className="switch">
                                                    <label>
                                                        <input type="checkbox" id={"status"+index} onClick={()=>statusChangeListner(index)}/>
                                                        <span className="lever switch-col-blue" ></span>
                                                    </label>
                                                </div>  
                                                {manageCheckStatus(index)}
                                            </td>
                                            <td className="text-right">
                                            <div className='action-btn'>                        
                                            <Link to={`/FAQDetails/${item.id}`} className="btn btn-default col-green"><i className="zmdi zmdi-eye"></i></Link>
                                            <Link to={`/FAQEdit/${item.id}`} className="btn btn-default col-green"><i className="zmdi zmdi-edit"></i></Link>
                                            <a onClick={()=> {deleteUserIdWise(item.id)}} className="btn btn-default col-red"><i className="zmdi zmdi-delete"></i></a>
                                            </div>    
                                            </td>
                                        </tr>
                                        )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </section>
    );
}
