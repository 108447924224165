import React, { useState,useEffect } from 'react'
import { useParams ,useNavigate} from 'react-router-dom'
import {getClassList,getDevisionList,getSchoolList,getUserList,createNotification,upload_file,fileUploadApiCall} from '../../api/apiHandler'
import Alert from '../../components/Alert/Alert';
import {uploadNotification} from '../../AWS/MyS3'

import Select from 'react-select';
import $ from 'jquery';


export default function NotificationCreate(props) {
    let id =  useParams()
    let navigate = useNavigate(); 

    window.Buffer = window.Buffer || require("buffer").Buffer;

      const sentType =  [
        { value: 'people_of_school', label: 'People Of School' },
        { value: 'teachers_only', label: 'Teachers Only' },
        { value: 'students_only', label: 'Student Only' },
        { value: 'one_class', label: 'One Class' },
        { value: 'one_division', label: 'One Division' },
        { value: 'students', label: 'Students' },
        { value: 'teachers', label: 'Teachers' }
      ]


    const [selectedNotificationFile, setNotificationFile] = useState(null);
    const [selectedSentType, setSelectedSentType] = useState(sentType[0]);
    const [selectedSchool, setSelectedSchool] = useState(null);
    const [selectedClass, setSelectedClass] = useState(null);
    const [selectedDivision, setSelectedDivision] = useState(null);

    const [selectedStudent, setSelectedStudent] = useState([]);
    const [selectedTeacher, setSelectedTeacher] = useState([]);

    const [schoolList, setSchoolList] = useState([]);
    const [classList, setClasslList] = useState([])
    const [devisionList, setDevisionList] = useState([])
    const [studentList, setStudentList] = useState([])
    const [teacherList, setTeacherList] = useState([])
 
    const initalValue = {title: "",desc:""}
    const [formValues, setFormValues] = useState(initalValue)

    const handleChange = (e) => {
        if (e.target.value.trim() == "") {
            e.target.value = e.target.value.trim()
        }
        const {name, value} = e.target;
        setFormValues({...formValues, [name]: value});
    }

    const handleSentChange=(data)=>{
        console.log(data)
        setSelectedSentType(data)
    }

    const handleSchoolChange=(data)=>{
        console.log(data)
        setSelectedSchool(data)
        geClassListApi(data.value)
        getUserListApi(data.value)
    }

    const handleClassChange=(data)=>{
        console.log(data)
        setSelectedClass(data)
        geDevisionListApi(selectedSchool.value,data.value)
    }

    const handleDivisionChange=(data)=>{
        console.log(data)
        setSelectedDivision(data)
    }

    const handleStudentChange=(data)=>{
        console.log(data)
        setSelectedStudent(data)
    }
    const handleTeacherChange=(data)=>{
        console.log(data)
        setSelectedTeacher(data)
    }

    const handleFileProfileInput = (e) => {
        props.isLoader(true);
        const file = e.target.files[0];
        const newFileName = new Date().getTime() + file.name;
        const content_type = file.type;
        const key = `cybersmarties/notification/${newFileName}`;
        upload_file({key, content_type}).then(data => {
            if (data.data.code === 1) {
                const signedUrl = data.data.data ? data.data.data : null;
                const fileParameters = {
                    signedUrl: signedUrl,
                    file: file,
                    contentType: content_type,
                    onProgress: null
                }
                fileUploadApiCall(fileParameters).then(resultdata => {
                    setNotificationFile(newFileName);
                    props.isLoader(false);
                }).catch(err => {
                    props.isLoader(false);
                    console.log('handleFileCoverInput', err);
                });
            }
        }).catch(err => {
            props.isLoader(false);
            console.log(err);
        });
    }

    const clickSubmitButton = (e)=>{
        e.preventDefault();
        var apiData={"title":formValues.title,"description":formValues.desc,"sent_type":selectedSentType.value}

        if(selectedNotificationFile!==null){
            apiData.image=selectedNotificationFile
        }

        if(selectedSentType.value==="people_of_school"){
            apiData.school_id=selectedSchool.value         
        }else if(selectedSentType.value==="one_class"){
            apiData.class_id=selectedClass.value
        }else if(selectedSentType.value==="one_division"){
            apiData.class_id=selectedClass.value
            apiData.division_id=selectedDivision.value            
        }
        else if(selectedSentType.value==="students"){
            
            let studentIdList=[]

            if(selectedStudent!==null){
                if(selectedStudent.length>0){
                    selectedStudent.forEach((item)=>{
                        studentIdList.push(item.value)                     
                    })
                    }
            }
    
            let studentId=studentIdList.join(",")
            apiData.student_id=studentId
    
        }else if(selectedSentType.value==="teachers"){

            let teacherIdList=[]

            if(selectedTeacher!==null){
                if(selectedTeacher.length>0){
                    selectedTeacher.forEach((item)=>{
                        teacherIdList.push(item.value)                     
                    })
                    }
            }
    
            let teacherId=teacherIdList.join(",")
            apiData.teachers_id=teacherId
        }

        props.isLoader(true)
        console.log("Api call",apiData)
        createNotification(apiData).then((resposnse)=>{
            console.log(resposnse)
            if(resposnse.data.code==1){
                props.isLoader(false)
                navigate(-1)
            }
        });

    }

    useEffect(() => {
        let mounted = true;
        getSchoolList({"page":"1","limit":"1000","sort_by":"id","order_by":"desc"}).then((resposnse)=>{
            console.log(resposnse.data)
            if(resposnse.data.code==1){
                let list =[]
                resposnse.data.data.list.map((item, index) => {
                    console.log({label:item.name,value:item.id})
                    list.push({label:item.name,value:item.id})
                })
                setSchoolList(list)
                setSelectedSchool(list[0])
                geClassListApi(list[0].value)
                getUserListApi(list[0].value)
            }
        });

        return () => mounted = false;
    }, []);


    const geClassListApi = (id)=>{
        getClassList({"page":"1","limit":"1000","sort_by":"id","order_by":"asc","school_id":id}).then((resposnse)=>{
            
            if(resposnse.data.code==1){
                let list =[]
                resposnse.data.data.list.map((item, index) => {
                    console.log({label:item.name,value:item.id})
                    list.push({label:item.name,value:item.id})
                })

                setClasslList(list);
                setSelectedClass(list[0])
                geDevisionListApi(id,list[0].value)
            }else{
                setClasslList([]);
                setSelectedClass(null)
            }
        })
    }

    const geDevisionListApi = (id,classId)=>{
        getDevisionList({"page":"1","limit":"1000","sort_by":"id","order_by":"asc","school_id":id,"class_id":classId}).then((resposnse)=>{
            if(resposnse.data.code==1){
                let list =[]
                resposnse.data.data.list.map((item, index) => {
                    console.log({label:item.name,value:item.id})
                    list.push({label:item.name,value:item.id})
                })

                setDevisionList(list);
                setSelectedDivision(list[0])
            }else{
                setDevisionList([]);
                setSelectedDivision(null)
            }
        })
    }

    const getUserListApi = (id)=>{
        getUserList({"user_type":"student","page":"1","limit":"1000","sort_by":"id","order_by":"desc","school_id":id}).then((resposnse)=>{
            setSelectedStudent([])
            if(resposnse.data.code==1){
                
                let list =[]
                resposnse.data.data.list.map((item, index) => {
                    list.push({label:item.username,value:item.id})
                })


                setStudentList(list);

            }else{
                setStudentList([]);
            }
        })

        getUserList({"user_type":"teacher","page":"1","limit":"1000","sort_by":"id","order_by":"desc","school_id":id}).then((resposnse)=>{
            console.log(resposnse)
            setSelectedTeacher([])
            if(resposnse.data.code==1){
                let list =[]
                resposnse.data.data.list.map((item, index) => {
                    list.push({label:item.name,value:item.id})
                })

                setTeacherList(list);
            }else{
                setTeacherList([]);
            }
        })

    }

    $(document).ready(function() {
        $('input[type="file"]').on("change", function() {
          let filenames = [];
          let files = this.files;
          if (files.length > 1) {
            filenames.push("Total Files (" + files.length + ")");
          } else {
            for (let i in files) {
              if (files.hasOwnProperty(i)) {
                filenames.push(files[i].name);
              }
            }
          }
          $(this)
            .next(".custom-file-label")
            .html(filenames.join(","));
        });
      });


    const setDropDownSchool =()=>{
        if( !(selectedSentType.value==="teachers_only" || selectedSentType.value==="students_only")){
            return (
                <>
                    <label htmlFor="name_en">Select School</label>
                        <div className="form-group">
                            <Select
                            value={selectedSchool}
                            onChange={handleSchoolChange}
                            options={schoolList}
                            name="selectSchoolData" 
                            />
                            <input name="selectedClass" className='dropHideInput' value={ (selectedSchool == null)? "" : selectedSchool.value  } id="selectedClass" type="text" required/> 
                        </div>
                </>
            )
        }else{
            return (
                <>
                </>
            )
        }
    }

    const setDropDownClass = ()=>{
        if(selectedSentType.value==="one_class" || selectedSentType.value==="one_division"){
            return (
                <>
                    <label htmlFor="name_en">Select Class</label>
                        <div className="form-group">
                            <Select
                            value={selectedClass}
                            onChange={handleClassChange}
                            options={classList}
                            name="selectClassData" 
                            />
                            <input name="selectClassData" className='dropHideInput' value={ (selectedClass == null)? "" : selectedClass.value  } id="selectClassData" type="text" required/> 
                        </div>
                
                </>
            )
        }else{
            return (
                <>
                </>
            )
        }
    }

    const setDropDownDivision = ()=>{
        if(selectedSentType.value==="one_division"){
            return (
                <>
                    <label htmlFor="name_en">Select Division</label>
                        <div className="form-group">
                            <Select
                            value={selectedDivision}
                            onChange={handleDivisionChange}
                            options={devisionList}
                            name="selectDivisionData" 
                            />
                            <input name="selectDivisionData" className='dropHideInput' value={ (selectedDivision == null)? "" : selectedDivision.value  } id="selectDivisionData" type="text" required/> 
                        </div>
                
                </>
            )
        }else{
            return (
                <>
                </>
            )
        }
    }

    const setDropDownStudent = ()=>{
        if(selectedSentType.value==="students"){
            return (
                <>
                    <label htmlFor="name_en">Select Student</label>
                        <div className="form-group">
                            <Select
                            isMulti
                            value={selectedStudent}
                            onChange={handleStudentChange}
                            options={studentList}
                            name="selectStudent" 
                            />
                            <input name="selectStudent" className='dropHideInput' value={ (selectedStudent == null)? "" : getSelectStudent()  } id="selectStudent" type="text" required/> 
                        </div>
                
                </>
            )
        }else{
            return (
                <>
                </>
            )
        }
    }

    const setDropDownTeacher = ()=>{
        if(selectedSentType.value==="teachers"){
            return (
                <>
                    <label htmlFor="name_en">Select Teacher</label>
                        <div className="form-group">
                            <Select
                            isMulti
                            value={selectedTeacher}
                            onChange={handleTeacherChange}
                            options={teacherList}
                            name="selectTeacher" 
                            />
                            <input name="selectTeacher" className='dropHideInput' value={ (selectedTeacher == null)? "" : getSelectTeacher()  } id="selectTeacher" type="text" required/> 
                        </div>
                
                </>
            )
        }else{
            return (
                <>
                </>
            )
        }
    }

    const getSelectStudent =()=>{
        let selectData=""
        if(selectedStudent!=null){
            if(selectedStudent.length>0){
                selectedStudent.forEach((item) =>{
                selectData=selectData+item.value
                })
            }
        }
        return selectData
    }

    const getSelectTeacher =()=>{
        let selectData=""
        if(selectedTeacher!=null){
            if(selectedTeacher.length>0){
                selectedTeacher.forEach((item) =>{
                selectData=selectData+item.value
                })
            }
        }
        return selectData
    }


  return (
    <section className="content">
    <div className="container-fluid"> 
        <div className="row clearfix ">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card m-t-15">
                    <div className="header">
                        <h2>Create Notification</h2>
                    </div>
                    <div className="body">
                    <form id="form_validation" method="" onSubmit={clickSubmitButton} action="#">
                                    
                                    <label htmlFor="title">Title</label>
                                    <div className="form-group">
                                        <div className="form-line">
                                            <input type="text" placeholder='Title' className="form-control" id="title"
                                                   name="title" required value={formValues.title}
                                                   onChange={handleChange}/>
                                        </div>
                                    </div>

                                    <label htmlFor="description">Description</label>
                                    <div className="form-group">
                                        <div className="form-line">
                                            <textarea placeholder='Description' cols="30" rows="5"
                                                      className="form-control no-resize" id="desc" name="desc"
                                                      required value={formValues.desc}
                                                      onChange={handleChange}></textarea>
                                        </div>
                                    </div>

                                    <label htmlFor="school_log">Image</label>
                                    <div className="form-group">
                                            <div className="custom-file">
                                                <input type="file" name="files[]" className="custom-file-input form-control" id="profileImage" onChange={handleFileProfileInput} accept="image/*"/>
                                                <label className="custom-file-label" htmlFor="customFile">Choose file</label>
                                            </div>
                                    </div>

                                    <label htmlFor="name_en">Select Sent Type</label>
                                    <div className="form-group">
                                    <Select
                                                    value={selectedSentType}
                                                    onChange={handleSentChange}
                                                    options={sentType}
                                                    name="selectSentType" 
                                                 />
                                      <input name="selectSentType" className='dropHideInput' value={ (selectedSentType == null)? "" : ""+selectedSentType.value  } id="selectType" type="text" required/>            
                                    </div>

                                    {setDropDownSchool()}
                                    {setDropDownClass()}
                                    {setDropDownDivision()}
                                    {setDropDownStudent()}
                                    {setDropDownTeacher()}
                            <button className="btn btn-raised btn-primary waves-effect" type="submit">SUBMIT</button>
                        </form>                     
                    </div>
                </div>
            </div>
        </div>
    </div>
    </section>
  )
}
