import React,{useEffect,useState,useCallback} from 'react'
import Heading from '../../components/Heading/Heading';
import { useParams,useNavigate} from "react-router-dom";
import { getDeshboardData, getOnlineUsers } from '../../api/apiHandler'


export default function Dashboard(props) {

    const [myDashooBoardData, setDashooBoardData] = useState(null)
    const [onlineusers, setOnlineUsers] = useState(null)

    useEffect(() => {
        let mounted = true;
        props.isLoader(true)
        getDeshboardData().then((resposnse) => {
            props.isLoader(false)
            if(resposnse.data.code==1){
                setDashooBoardData(resposnse.data.data);
            }else{
                setDashooBoardData(null);
            }
        })
        getOnlineUsers().then((resposnse) => {
            if (resposnse.data.code === 1) {
                setOnlineUsers(resposnse.data.data);
            }
        })
        return () => mounted = false;
    }, []);

    if (myDashooBoardData==null) return null;
     const detailOnlineUsers = () => {
        if (onlineusers) {
            let thedetails = (onlineusers.details)
            .map((user, i) => {
                return <center key={i}><strong>{((user.role).replace('_', ' ')).toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')}</strong>: {user.count}<br/></center>
            })
            return <p>{thedetails}</p>;
        }
        
     }

  return (
    <section className="content home">
        <Heading title="Dashboard"/>
        <div className="container-fluid">
        <div className="row clearfix social-widget">
            <div className="col-xl-4 col-lg-4 col-md-4 col-6">
                <div className="card info-box-2 hover-zoom-effect facebook-widget">
                    <div className="icon"><i className="zmdi zmdi-online"></i></div>
                    <div className="content">
                        <div className="text">Total Online</div>
                        <div className="number">{onlineusers ? onlineusers.total_online : 'N/A'}</div>
                        {onlineusers ? detailOnlineUsers() : ''}
                    </div>
                </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-6">
                <div className="card info-box-2 hover-zoom-effect facebook-widget">
                    <div className="icon"><i className="zmdi zmdi-accounts"></i></div>
                    <div className="content">
                        <div className="text">Total Student</div>
                        <div className="number">{myDashooBoardData.total_student}</div>
                    </div>
                </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-6">
                <div className="card info-box-2 hover-zoom-effect instagram-widget">
                    <div className="icon"><i className="zmdi zmdi-accounts"></i></div>
                    <div className="content">
                        <div className="text">Total Teacher</div>
                        <div className="number">{myDashooBoardData.total_teacher}</div>
                    </div>
                </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-6">
                <div className="card info-box-2 hover-zoom-effect twitter-widget">
                    <div className="icon"><i className="zmdi zmdi-graduation-cap"></i></div>
                    <div className="content">
                        <div className="text">Total schools</div>
                        <div className="number">{myDashooBoardData.total_school}</div>
                    </div>
                </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-6">
                <div className="card info-box-2 hover-zoom-effect google-widget">
                    <div className="icon"><i className="zmdi zmdi-library"></i></div>
                    <div className="content">
                        <div className="text">Total Class</div>
                        <div className="number">{myDashooBoardData.total_class}</div>
                    </div>
                </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-6">
                <div className="card info-box-2 hover-zoom-effect linkedin-widget">
                    <div className="icon"><i className="zmdi zmdi-library"></i></div>
                    <div className="content">
                        <div className="text">Total Division</div>
                        <div className="number">{myDashooBoardData.total_division}</div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </section>
    );
}
