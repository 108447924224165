import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  createAdmin,
  getAdminDetails,
  editAdmin,
  getSchoolList,
} from "../../api/apiHandler";
import Alert from "../../components/Alert/Alert";
import {
  uploadAdminImages,
  getMainBucketPath,
  getAdminBucketPath,
} from "../../AWS/MyS3";
import { upload_file, fileUploadApiCall } from "../../api/apiHandler";
import Select from "react-select";
import $ from "jquery";

export default function CreateAdmin(props) {
  let id = useParams();
  let navigate = useNavigate();

  window.Buffer = window.Buffer || require("buffer").Buffer;
  // const ReactS3Client = new S3(uploadAdminImages());

  const roleData = [
    { value: "school_admin", label: "School Admin" },
    { value: "monitoring_admin", label: "Monitoring Admin" },
  ];

  const [passwordOld, setPasswordOld] = useState("");
  const initalValue = {
    name: "",
    userName: "",
    email: "",
    password: "",
    countryCode: "",
    phoneNumber: "",
  };
  const [formValues, setFormValues] = useState(initalValue);
  const [selectedProfileFile, setProfileFile] = useState(null);
  const [selectedOptionRole, setSelectedOptionRole] = useState(roleData[0]);

  const [selectedSchool, setSelectedSchool] = useState([]);
  const [schoolList, setSchoolList] = useState([]);

  const [msg, setMsg] = useState(null);

  const showAlert = (msgDisplay, type) => {
    setMsg({
      msg: msgDisplay,
      type: type,
    });
  };

  const handleChange = (e) => {
    if (e.target.value.trim() == "") {
      e.target.value = e.target.value.trim();
    }
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleChangePassword = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleRoleChange = (data) => {
    console.log(data);
    setSelectedOptionRole(data);
  };

  const handleSchoolChange = (data) => {
    console.log(data);
    setSelectedSchool(data);
  };

  const handleFileProfileInput = (e) => {
    props.isLoader(true);
    const file = e.target.files[0];
    const newFileName = new Date().getTime() + file.name;
    console.log(`Loading ${file.name}`)
    const content_type = file.type;
    const key = `cybersmarties/admin/${newFileName}`;
    upload_file({key, content_type}).then(data => {
      if(data.data.code === 1) {
        const signedUrl = data.data.data? data.data.data : null;
        const fileParameters = {
          signedUrl: signedUrl,
          file: file,
          contentType: content_type,
          onProgress: null
        }
        fileUploadApiCall(fileParameters).then(resultdata => {
          console.log(resultdata);
          setProfileFile(newFileName);
          props.isLoader(false);
        }).catch(err => {
          props.isLoader(false);
          console.log('handleFileProfileInput', err);
        });
      }
    }).catch(err => {
      props.isLoader(false);
      console.log(err);
    });
  };

  const clickSubmitButton = (e) => {
    e.preventDefault();

    if (props.type == "e") {
      updateApiCall(formValues);
    } else {
      createApiCall(formValues);
    }
  };

  const createApiCall = (initValues) => {
    console.log("Create Api call");
    console.log(initValues);
    props.isLoader(true);

    const valueLanguageChecked = $(
      "input[type='radio'][name='group1']:checked"
    ).val();

    var schoolIdList = [];

    if (selectedSchool !== null) {
      if (selectedSchool.length > 0) {
        selectedSchool.forEach((item) => {
          schoolIdList.push(item.value);
        });
      }
    }

    var allSelect = selectedSchool.filter((item) => {
      return item.value === "all";
    });

    var isShowAll = "0";
    if (allSelect.length > 0) {
      var isShowAll = "1";
      schoolIdList = [];
    }

    console.log("Select All", allSelect);

    console.log(schoolIdList);

    let apiData = {
      name: initValues.name,
      email: initValues.email,
      username: initValues.userName,
      password: initValues.password,
      country_code: initValues.countryCode,
      phone_number: initValues.phoneNumber,
      profile_image: selectedProfileFile,
      role: selectedOptionRole.value,
      language: valueLanguageChecked,
      school_ids: schoolIdList,
      show_all_school: isShowAll,
    };

    console.log(apiData);

    createAdmin(apiData).then((resposnse) => {
      props.isLoader(false);
      console.log(resposnse);
      if (resposnse.data.code == 1) {
        navigate(-1);
      } else {
        showAlert(resposnse.data.message, "danger");
      }
    });
  };

  const updateApiCall = (initValues) => {
    console.log("Edit Api call");
    console.log(initValues);
    props.isLoader(true);

    const valueLanguageChecked = $(
      "input[type='radio'][name='group1']:checked"
    ).val();


    var schoolIdList = [];

    if (selectedSchool !== null) {
      if (selectedSchool.length > 0) {
        selectedSchool.forEach((item) => {
          schoolIdList.push(item.value);
        });
      }
    }

    var allSelect = selectedSchool.filter((item) => {
      return item.value === "all";
    });

    var isShowAll = "0";
    if (allSelect.length > 0) {
      var isShowAll = "1";
      schoolIdList = [];
    }

    console.log("Select All", allSelect);

    console.log(schoolIdList);

    let apiData = {
      admin_id: id.userId,
      name: initValues.name,
      email: initValues.email,
      username: initValues.userName,
      password: initValues.password,
      country_code: initValues.countryCode,
      phone_number: initValues.phoneNumber,
      profile_image: selectedProfileFile,
      role: selectedOptionRole?.value ? selectedOptionRole.value : "sub_admin",
      language: valueLanguageChecked,
      school_ids: schoolIdList,
      show_all_school: isShowAll,
    };

    if (passwordOld === initValues.password) {
      delete apiData.password;
    }

    console.log(apiData);

    editAdmin(apiData).then((resposnse) => {
      props.isLoader(false);
      console.log(resposnse);
      if (resposnse.data.code == 1) {
        navigate(-1);
      } else {
        showAlert(resposnse.data.message, "danger");
      }
    });
  };

  useEffect(() => {
    let mounted = true;
  
    getSchoolList({
      page: "1",
      limit: "1000",
      sort_by: "id",
      order_by: "desc",
    }).then((resposnse) => {
      if (resposnse.data.code == 1) {
        let list = [];
        list.push({ label: "All", value: "all" });
        resposnse.data.data.list.map((item, index) => {
          list.push({ label: item.name, value: item.id });
        });
        setSchoolList(list);
        if (props.type == "e") {
          callGetDetailsAPI(list)
        }
      } else {
        navigate(-1);
      }
    });

    return () => (mounted = false);
  }, []);


  const callGetDetailsAPI =(listSchool)=>{
    if (props.type == "e") {
      console.log("Edit");
      console.log(id);
      props.isLoader(true);
      getAdminDetails({ admin_id: id.userId }).then((resposnse) => {
        props.isLoader(false);
        console.log(resposnse);
        if (resposnse.data.code == 1) {
          setFormValues({
            name: resposnse.data.data.name,
            userName: resposnse.data.data.username,
            email: resposnse.data.data.email,
            password: resposnse.data.data.password,
            countryCode: resposnse.data.data.country_code,
            phoneNumber: resposnse.data.data.phone_number,
          });
          let profileImage = resposnse.data.data.profile_image
            .replace(getMainBucketPath(), "")
            .replace(getAdminBucketPath(), "");

          $("#profileImage").next(".custom-file-label").html(profileImage);

          if (!(profileImage === "")) {
            $("#profileImage").prop("required", false);
          }
          
          console.log(resposnse.data.data.language);
          if (resposnse.data.data.language === "ar") {
            $("#radio_arabic").prop("checked", true);
          } else if (resposnse.data.data.language === "tr") {
            $("#radio_turkish").prop("checked", true);
          } else {
            $("#radio_english").prop("checked", true);
          }

          setProfileFile(profileImage);
          setPasswordOld(resposnse.data.data.password);

          let selectData = roleData.filter((item) => {
            return item.value === resposnse.data.data.role;
          });
          console.log(selectData);
          setSelectedOptionRole(selectData[0]);

          if(resposnse.data.data.show_all_school.toString()=="1"){
            setSelectedSchool(listSchool[0]);
          }else{
            var selectedSchooList=[]

            resposnse.data.data.schools.forEach((item)=>{

              listSchool.forEach((subItem)=>{
  
                  if(subItem.value===item.school_id){
                    selectedSchooList.push(subItem);
                  }  
              })
  
          })
          setSelectedSchool(selectedSchooList);
        
          }
        } else {
          navigate(-1);
        }
      });
    }
  }

  $(document).ready(function () {
    $('input[type="file"]').on("change", function () {
      let filenames = [];
      let files = this.files;
      if (files.length > 1) {
        filenames.push("Total Files (" + files.length + ")");
      } else {
        for (let i in files) {
          if (files.hasOwnProperty(i)) {
            filenames.push(files[i].name);
          }
        }
      }
      $(this).next(".custom-file-label").html(filenames.join(","));
    });
  });

  const getSelectValue = () => {
    let selectData = "";
    if (selectedSchool != null) {
      if (selectedSchool.length > 0) {
        selectedSchool.forEach((item) => {
          selectData = selectData + item.value;
        });
      }
    }
    return selectData;
  };

  return (
    <section className="content">
      <Alert alert={msg} />

      <div className="container-fluid">
        <div className="row clearfix ">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="card m-t-15">
              <div className="header">
                <h2>{props.type == "e" ? "Edit Admin" : "Create Admin"}</h2>
              </div>
              <div className="body">
                <form
                  id="form_validation"
                  method=""
                  onSubmit={clickSubmitButton}
                  action="#"
                >
                  <label htmlFor="name">Name</label>
                  <div className="form-group">
                    <div className="form-line">
                      <input
                        type="text"
                        placeholder="Name"
                        className="form-control"
                        id="name"
                        name="name"
                        required
                        value={formValues.name}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <label htmlFor="user_name">Username</label>
                  <div className="form-group">
                    <div className="form-line">
                      <input
                        type="text"
                        placeholder="Username"
                        className="form-control"
                        id="userName"
                        name="userName"
                        required
                        value={formValues.userName}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <label htmlFor="email">Email</label>
                  <div className="form-group">
                    <div className="form-line">
                      <input
                        type="email"
                        placeholder="Email"
                        className="form-control"
                        id="email"
                        name="email"
                        required
                        value={formValues.email}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <label htmlFor="password">Password</label>
                  <div className="form-group">
                    <div className="form-line">
                      <input
                        type="password"
                        placeholder="Password"
                        className="form-control"
                        id="password"
                        name="password"
                        required
                        value={formValues.password}
                        onChange={handleChangePassword}
                      />
                    </div>
                  </div>
                  <div className="row clearfix">
                    <div className="col-md-2">
                      <label htmlFor="school_country_code">Country Code</label>
                      <div className="form-group">
                        <div className="form-line">
                          <input
                            type="tel"
                            placeholder="Country Code"
                            className="form-control"
                            id="countryCode"
                            name="countryCode"
                            required
                            value={formValues.countryCode}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-10">
                      <label htmlFor="school_phone_number">Phone Number</label>
                      <div className="form-group">
                        <div className="form-line">
                          <input
                            type="tel"
                            placeholder="Phone Number"
                            className="form-control"
                            id="phoneNumber"
                            name="phoneNumber"
                            required
                            value={formValues.phoneNumber}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <label htmlFor="school_log">Profile Image</label>
                  <div className="form-group">
                    <div className="custom-file">
                      <input
                        type="file"
                        name="files[]"
                        className="custom-file-input form-control"
                        id="profileImage"
                        required
                        onChange={handleFileProfileInput}
                        accept="image/*"
                      />
                      <label className="custom-file-label" htmlFor="customFile">
                        Choose file
                      </label>
                    </div>
                  </div>

                  <label htmlFor="name_en">Select School</label>
                <div className="form-group">
                    <Select
                      isMulti
                      value={selectedSchool}
                      onChange={handleSchoolChange}
                      options={schoolList}
                      name="selectSchoolData"
                    />

                    <input
                      name="classAndDevision"
                      className="dropHideInput"
                      value={selectedSchool == null ? "" : getSelectValue()}
                      id="classAndDevision"
                      type="text"
                      required
                    />
                  </div>

                  {
                    <>
                    <label htmlFor="name_en">Select Role</label>
                    <div className="form-group">
                      <Select
                        value={selectedOptionRole}
                        onChange={handleRoleChange}
                        options={roleData}
                        name="selectRoleData"
                      />
                      <input
                        name="school"
                        className="dropHideInput"
                        value={
                          selectedOptionRole == null
                            ? ""
                            : "" + selectedOptionRole.value
                        }
                        id="role"
                        type="text"
                        required
                      />
                    </div>
                  </>

                  }
                  <label htmlFor="name_en">Select Language</label>
                  <div className="form-group d-flex">
                    <div className="demo-radio-button">
                      <input
                        name="group1"
                        type="radio"
                        id="radio_english"
                        className="radio-col-light-blue"
                        value="en"
                        required
                      />
                      <label for="radio_english">English</label>
                      <input
                        name="group1"
                        type="radio"
                        id="radio_arabic"
                        className="radio-col-light-blue"
                        value="ar"
                        required
                      />
                      <label for="radio_arabic">Arabic</label>
                      <input
                        name="group1"
                        type="radio"
                        id="radio_turkish"
                        className="radio-col-light-blue"
                        value="tr"
                        required
                      />
                      <label for="radio_turkish">Turkish</label>
                    </div>
                  </div>
                  <button
                    className="btn btn-raised btn-primary waves-effect"
                    type="submit"
                  >
                    SUBMIT
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
