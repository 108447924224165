import React, { useState ,useEffect} from 'react'
import { Link,useNavigate,useParams } from 'react-router-dom'
import {getFunZoneDetails} from '../../api/apiHandler'
import Alert from '../../components/Alert/Alert';
import Swal from 'sweetalert2'


export default function FunZoneDetails(props) {

    let navigate = useNavigate(); 

    let  id  = useParams();


    const [funZoneDetails, setfunZoneDetails] = useState(null)

    useEffect(() => {
        let mounted = true;
        props.isLoader(true)
        getFunZoneDetails({"funzone_id":id.funId}).then((resposnse)=>{
            console.log(resposnse)
            if(resposnse.data.code==1){
              setfunZoneDetails(resposnse.data.data)
            }else{
                navigate(-1)
            }
      })

      return () => mounted = false;
    }, []);

    const showContent = (htmlData)=>{
        Swal.fire({
            title: funZoneDetails.title,
            html:htmlData,
            showCloseButton: true,
            focusConfirm: false
        })
    }

    const getNameList = (listData)=>{
        var className=[]

        if(listData!==null){
            if(listData.length>0){
                listData.forEach((item)=>{
                    className.push(item.name)                     
                })
                }
        }

        return className.join(", ")
    }

    if (funZoneDetails==null ) return null;
    props.isLoader(false)

    return (
        <section className="content home">   
        <div className="container-fluid"> 
            <div className="row clearfix">
                <div className="col-lg-12 col-md-12 col-sm-12 m-t-15">
                    <div className="card">
                        <div className="header">
                        <h2>Fun Zone Details</h2>
                        </div>
                        <div className="body">
                        <div className="row justify-content-center align-self-center">
                            <div className="col-xl-4 align-self-center m-b-15"> 
                                <img className="img-fluid img-thumbnail" src={funZoneDetails.cover_image} alt={funZoneDetails.cover_image}/> 
                            </div>
                        </div>
                        <div className="tab-content">
                        <div role="tabpanel" className="tab-pane active" id="official" aria-expanded="true">
                            <li className="list-group-item"><b className="mr-2">Id : </b> {funZoneDetails.id}</li>
                            <li className="list-group-item"><b className="mr-2">Title : </b> {funZoneDetails.title}</li>
                            <li className="list-group-item"><b className="mr-2">Title Arabic : </b> {funZoneDetails.ar_title}</li>
                            <li className="list-group-item"><b className="mr-2">Title Turkish : </b> {funZoneDetails.tu_title}</li>

                            <li className="list-group-item"><b className="mr-2">Description : </b> {funZoneDetails.description}</li>
                            <li className="list-group-item"><b className="mr-2">Description Arabic : </b> {funZoneDetails.ar_description}</li>
                            <li className="list-group-item"><b className="mr-2">Description Turkish : </b> {funZoneDetails.tu_description}</li>

                            <li className="list-group-item"><b className="mr-2">Category : </b> {funZoneDetails.category}</li>
                            <li className="list-group-item"><b className="mr-2">Content Type : </b> {funZoneDetails.content_type}</li>
 
                            <li className="list-group-item"><b className="mr-2">Content : </b> <button type="button" className="btn btn-raised btn-primary m-t-15 m-b-15 waves-effect right" onClick={()=>showContent(funZoneDetails.content)}>View</button></li>
                            <li className="list-group-item"><b className="mr-2">Content Arabic : </b> <button type="button" className="btn btn-raised btn-primary m-t-15 m-b-15 waves-effect right" onClick={()=>showContent(funZoneDetails.ar_content)}>View</button></li>
                            <li className="list-group-item"><b className="mr-2">Content Turkish : </b> <button type="button" className="btn btn-raised btn-primary m-t-15 m-b-15 waves-effect right" onClick={()=>showContent(funZoneDetails.tu_content)}>View</button></li>

                            <li className="list-group-item"><b className="mr-2">Class : </b> {getNameList(funZoneDetails.classes_name)}</li>

                            <li className="list-group-item"><b className="mr-2">Create Date : </b> {funZoneDetails.created_at}</li>
                            <li className="list-group-item"><b className="mr-2">Status : </b> {(funZoneDetails.is_active==1)?"Active":"Inactive"}</li>

                       </div>
                        </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
     </section>
    );
}