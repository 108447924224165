import React, { useState ,useEffect} from 'react'
import { Link,useNavigate,useParams } from 'react-router-dom'
import {getFAQDetails} from '../../api/apiHandler'


export default function FAQSDetails(props) {

    let navigate = useNavigate(); 

    let  id  = useParams();


    const [FAQDetails, setFAQDetails] = useState(null)

    useEffect(() => {
        let mounted = true;
        props.isLoader(true)
        getFAQDetails({"faqs_id":id.faqId}).then((resposnse)=>{
            console.log(resposnse)
            if(resposnse.data.code==1){
              setFAQDetails(resposnse.data.data)
            }else{
             //   navigate(-1)
            }
      })

      return () => mounted = false;
    }, []);

    if (FAQDetails==null ) return null;
    props.isLoader(false)

    return (
        <section className="content home">   
        <div className="container-fluid"> 
            <div className="row clearfix">
                <div className="col-lg-12 col-md-12 col-sm-12 m-t-15">
                    <div className="card">
                        <div className="header">
                        <h2>FAQ Details</h2>
                        </div>
                        <div className="body">
                        <div className="tab-content">
                        <div role="tabpanel" className="tab-pane active" id="official" aria-expanded="true">
                            <li className="list-group-item"><b className="mr-2">Id : </b> {FAQDetails.id}</li>

                            <li className="list-group-item"><b className="mr-2">Question English : </b> {FAQDetails.en_question}</li>
                            <li className="list-group-item"><b className="mr-2">Answer English : </b> {FAQDetails.en_answer}</li>

                            <li className="list-group-item"><b className="mr-2">Question Arabic : </b> {FAQDetails.ar_question}</li>
                            <li className="list-group-item"><b className="mr-2">Answer Arabic : </b> {FAQDetails.ar_answer}</li>

                            <li className="list-group-item"><b className="mr-2">Question Turkish : </b> {FAQDetails.tu_question}</li>
                            <li className="list-group-item"><b className="mr-2">Answer Turkish : </b> {FAQDetails.tu_answer}</li>

                            <li className="list-group-item"><b className="mr-2">Create Date : </b> {FAQDetails.created_at}</li>
                            <li className="list-group-item"><b className="mr-2">Updated Date : </b> {FAQDetails.updated_at}</li>
                            <li className="list-group-item"><b className="mr-2">Status : </b> {(FAQDetails.is_active==1)?"Active":"Inactive"}</li>
                       </div>
                        </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
     </section>
    );
}