import React, { useState ,useEffect} from 'react'
import 'jquery/dist/jquery.min.js'
import $ from 'jquery'; 
import Heading from '../../components/Heading/Heading';
import { Link ,useNavigate} from 'react-router-dom'
import {getSchoolList, getWBStatData} from '../../api/apiHandler'
import Alert from '../../components/Alert/Alert';
import { renderToStaticMarkup } from 'react-dom/server'
//import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import Select from "react-select";
import moment from "moment";
import * as XLSX from 'xlsx'

export default function Download(props) {
    const [schoolList, setSchoolList] = useState(null)
    const [selectedSchool, setSelectedSchool] = useState([]);

    useEffect(() => {
        let mounted = true;
        props.isLoader(true)

        getSchoolList({"page":"1","limit":"1000","sort_by":"id","order_by":"desc"}).then((response) => {
            if (response.data.code == 1) {
                let list =[]
                response.data.data.list.map((item, index) => {
                    list.push({label:item.name,value:item.id})
                })
                setSchoolList(list);
            } else {
                setSchoolList([]);
            }
        });
    
        return () => mounted = false;
    }, []);

    const handleSchoolChange=(data)=>{
        setSelectedSchool(data);
    }

    const downloadFile = (extraData)=> {
        var data = {}
        var schoolId = $('input[name="selectSchool"]').val();
        
        if (schoolId == '') {
            alert("Please select a school!");return true;
        } else {
            data.school_id = schoolId;
        }
        
        getWBStatData(data).then((response) => {
            const workbook = XLSX.utils.book_new();
                            
            var chatBehaviourStatData = response.data.data.chatBehaviourStatData;
            var friendRequestReasonStatData = response.data.data.friendRequestReasonStatData;
            var blockWordStatData = response.data.data.blockWordStatData;
            var positiveBotStatementStatData = response.data.data.positiveBotStatementStatData;
            var negativeBotStatementStatData = response.data.data.negativeBotStatementStatData;
            var conversationStatData = response.data.data.conversationStatData;
            var firstLevelBotOptionData = response.data.data.firstLevelBotOptionData;
            
            // Set chat behaviour data of hidden monitored messages
            var worksheet = XLSX.utils.json_to_sheet(chatBehaviourStatData.monitored_hidden_msg_type_wise_count);
            /*XLSX.utils.sheet_add_aoa(worksheet, [[""]], { origin: -1 });
            XLSX.utils.sheet_add_aoa(worksheet, [["Total Chat Count : " + chatBehaviourStatData.tot_chat_count[0].count]], { origin: -1 });
            XLSX.utils.sheet_add_aoa(worksheet, [[""]], { origin: -1 });
            XLSX.utils.sheet_add_aoa(worksheet, [["Total Post Count : " + chatBehaviourStatData.tot_post_count[0].count]], { origin: -1 });
            XLSX.utils.sheet_add_aoa(worksheet, [[""]], { origin: -1 });
            XLSX.utils.sheet_add_aoa(worksheet, [["Total Comment Count : " + chatBehaviourStatData.tot_comment_count[0].count]], { origin: -1 });
            XLSX.utils.sheet_add_aoa(worksheet, [[""]], { origin: -1 });
            XLSX.utils.sheet_add_aoa(worksheet, [["Parameter : " + chatBehaviourStatData.parameter]], { origin: -1 });*/
            XLSX.utils.book_append_sheet(workbook, worksheet, 'hiddenMsgTypeWiseCount');
            
            // Set chat behaviour data type wise count
            var worksheet = XLSX.utils.json_to_sheet([{total_chat_count:chatBehaviourStatData.tot_chat_count[0].count, total_post_count:chatBehaviourStatData.tot_post_count[0].count,total_comment_count:chatBehaviourStatData.tot_comment_count[0].count}]);
            XLSX.utils.book_append_sheet(workbook, worksheet, 'typeWiseMessageCount');
            
            // Set friend request reason data
            worksheet = XLSX.utils.json_to_sheet(friendRequestReasonStatData.friend_request_reason_wb_params_count);
            XLSX.utils.book_append_sheet(workbook, worksheet, 'friendReqReasonStatData');
            
            // Set blocked word data
            worksheet = XLSX.utils.json_to_sheet(blockWordStatData.block_word_data);
            /*XLSX.utils.sheet_add_aoa(worksheet, [[""]], { origin: -1 });
            XLSX.utils.sheet_add_aoa(worksheet, [["Total Blocked words : " + blockWordStatData.block_word_total[0].count]], { origin: -1 });
            XLSX.utils.sheet_add_aoa(worksheet, [[""]], { origin: -1 });
            XLSX.utils.sheet_add_aoa(worksheet, [["Parameter : " + blockWordStatData.parameter]], { origin: -1 });*/
            XLSX.utils.book_append_sheet(workbook, worksheet, 'blockWordStatData');
            
            // Set positiveBotStatementStatData data
            worksheet = XLSX.utils.json_to_sheet(positiveBotStatementStatData.statement_wb_params_data);
            XLSX.utils.book_append_sheet(workbook, worksheet, 'posBotStatementStatData');
            
            // Set  negativeBotStatementStatData data
            worksheet = XLSX.utils.json_to_sheet(negativeBotStatementStatData.statement_wb_params_data);
            XLSX.utils.book_append_sheet(workbook, worksheet, 'negBotStatementStatData');
            
            // Set  conversationStatData data
            worksheet = XLSX.utils.json_to_sheet(conversationStatData.converation_wb_params_count);
            XLSX.utils.book_append_sheet(workbook, worksheet, 'conversationStatData');
            
            // Set  firstLevelBotOptionData data
            worksheet = XLSX.utils.json_to_sheet([{botPosFLCount:firstLevelBotOptionData.botPosFLCount[0].count, botNegFLCount:firstLevelBotOptionData.botNegFLCount[0].count}]);
            XLSX.utils.book_append_sheet(workbook, worksheet, 'firstLevelBotOptionData');
            
            /*worksheet = XLSX.utils.json_to_sheet(testData2);
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet2');*/
            
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const blob = new Blob([excelBuffer], {type: 'application/octet-stream'});
            saveAs(blob, 'CS-School-WellBeing-Data-' + moment().format("YYYY-MM-DD_HH_mm") +  '.xlsx');
        })
    }
 
    if (schoolList == null) return null;
    props.isLoader(false)
    return (
        <section className="content home">
            <Heading title="Mange School/Division"/>
            <div className="container-fluid"> 
            <div className="row clearfix">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="card">
                        <div className="body">
                            <div className="col-lg-2 col-md-2 col-sm-2 m-t-15 m-l-20">
                                <div className="card row">
                                    <label htmlFor="email">Select School</label>
                                    <Select
                                        isMulti={false}
                                        placeholder="Select"
                                        value={selectedSchool}
                                        onChange={(data) => handleSchoolChange(data)}
                                        options={schoolList}
                                        name="selectSchool" 
                                        id="selectSchool"
                                    />
                                </div>
                            </div>
                            <button
                                className="btn btn-raised btn-primary waves-effect"
                                onClick={downloadFile}
                                style={{
                                    alignSelf: "center",
                                    justifyContent: "center",
                                }}
                            >
                                Download
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </section>
    );
}