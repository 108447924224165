import React, { useState ,useEffect} from 'react'
import { Link,useNavigate,useParams } from 'react-router-dom'
import {getDevisionDetails, getClassDetails} from '../../api/apiHandler'
import Alert from '../../components/Alert/Alert';


export default function DivisionDetails(props) {

    let navigate = useNavigate(); 

    let { divId } = useParams();


    const [divisionDetails, setDivisionDetails] = useState(null)

    useEffect(() => {
        let mounted = true;
        props.isLoader(true)
        getDevisionDetails({"division_id":divId}).then((response)=>{
            console.log(response)
            if(response.data.code==1){
                setDivisionDetails(response.data.data);
                props.isLoader(false)
                getClassDetails({"class_id":response.data.data.class_id}).then((response_class)=>{
                    console.log('class_details',response_class);
                    if(response_class.data.code==1){
                        setDivisionDetails({...response.data.data, class_name: response_class.data.data.name});
                    }                
                })
            }else{
                navigate(-1)
            }
        })
      return () => mounted = false;
    }, []);

    if (divisionDetails==null ) return null;

    return (
        <section className="content home">   
        <div className="container-fluid"> 
            <div className="row clearfix">
                <div className="col-lg-12 col-md-12 col-sm-12 m-t-15">
                    <div className="card">
                        <div className="header">
                        <h2>Division Details</h2>
                        </div>
                        <div className="body">
                        <div className="row justify-content-center align-self-center">
                            <div className="col-xl-4 align-self-center m-b-15"> 
                                <img className="img-fluid img-thumbnail" src={divisionDetails.logo_image} alt={divisionDetails.logo_image}/> 
                            </div>
                        </div>
                        <div className="row justify-content-center align-self-center">
                            <div className="col-xl-4 align-self-center m-b-15"> 
                                <img className="img-fluid img-thumbnail" src={divisionDetails.cover_image} alt={divisionDetails.cover_image}/> 
                            </div>
                        </div>
                        <div className="tab-content">
                        <div role="tabpanel" className="tab-pane active" id="official" aria-expanded="true">
                            <li className="list-group-item"><b className="mr-2">Id : </b> {divisionDetails.id}</li>
                            <li className="list-group-item"><b className="mr-2">Name : </b> {divisionDetails.name}</li>
                            <li className="list-group-item"><b className="mr-2">Name ar: </b>  {divisionDetails.ar_name}</li>
                            <li className="list-group-item"><b className="mr-2">Name tu: </b>  {divisionDetails.tu_name}</li>
                            <li className="list-group-item"><b className="mr-2">School Id: </b>  {divisionDetails.school_id}</li>
                            <li className="list-group-item"><b className="mr-2">Class Id: </b>  {divisionDetails.class_id}</li>
                            <li className="list-group-item"><b className="mr-2">Class Name: </b>  {divisionDetails.class_name}</li>
                            <li className="list-group-item"><b className="mr-2">Create Date : </b> {divisionDetails.created_at}</li>
                        </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card">                    
                    <div className="header">
                        <h2>Assign Teacher List </h2>
                    </div>
                    <div className="body">
                        <table id="mangeAssignSchool" className="table table-bordered table-striped table-hover dataTable js-exportable">
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Teacher Id</th>
                                    <th>Teacher</th>
                                    <th>Role</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th>Id</th>
                                    <th>Teacher Id</th>
                                    <th>Teacher</th>
                                    <th>Role</th>
                                </tr>
                            </tfoot>
                            <tbody id="devisionId">
                            { 
                            divisionDetails.teacher_list && divisionDetails.teacher_list.map((item)=>{
                                    return(
                                      <tr key={item.id}>
                                            <td>{item.id}</td>
                                            <td>{item.teacher_id}</td>
                                            <td>{item.teacher}</td>
                                            <td>{item.role}</td>
                                      </tr>    
                                    )
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
                </div>

            </div>
        </div>
     </section>
    );
}