
export function loginRedirectCall(){
    let path= window.location.protocol+"//"+window.location.host+"/signIn" 
//    window.open(path,'mywindow').focus()
    window.location.href = path;
  }

export function homeRedirectCall(){
    let path= window.location.protocol+"//"+window.location.host
//    window.open(path,'mywindow').focus()
    window.location.href = path;  
}

export function logOutRedirectCall(){
  localStorage.removeItem("isLogin");
  localStorage.removeItem("userData");
  localStorage.removeItem("id");
  localStorage.removeItem("token");
  localStorage.removeItem("name");
  localStorage.removeItem("email");

  loginRedirectCall()
}

export function loginRedirectCallWithDataStore(data){
  console.log("Redirect Call")
  console.log(data)
  localStorage.setItem("isLogin",true);
  localStorage.setItem("userData",JSON.stringify(data));
  localStorage.setItem("id",data.id);
  localStorage.setItem("token",data.token);
  localStorage.setItem("name",data.name);
  localStorage.setItem("email",data.email);
  loginRedirectCall()
}


  