import React, { useState ,useEffect} from 'react'
import { Link,useNavigate,useParams } from 'react-router-dom'
import {getAvaterList,updateAvatarStatus,deleteAvatar} from '../../api/apiHandler'
//Datatable Modules
import 'jquery/dist/jquery.min.js'
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import $ from 'jquery'; 
import Heading from '../../components/Heading/Heading';
import { renderToStaticMarkup } from 'react-dom/server'

export default function AvatarListing(props) {

    const [myAvatarList, setAvatarList] = useState(null)

    useEffect(() => {
        let mounted = true;
        props.isLoader(true)
        getAvaterList({"page":"1","limit":"1000","sort_by":"id","order_by":"desc"}).then((resposnse)=>{
            console.log(resposnse)
            if(resposnse.data.code==1){
                setAvatarList(resposnse.data.data.list);
            }else{
                setAvatarList([]);
            }
        })
        return () => mounted = false;
    }, []);


    $(document).ready(function () {
        $('#avatar').DataTable(
            {
                pagingType: 'full_numbers',
                  pageLength: 10,
                  order: [[0, 'desc']],
                  processing: true,
                  dom: 'Bfrtip',
                  buttons: [],
                  searching: false,
                 "bDestroy": true
            }
        );
    });

    let navigate = useNavigate(); 
    
    const redirectCreateAvatar = ()=>{
        let path ="/createAvatar"
        navigate(path)
    }

    const statusChangeListner = (position)=>{

        console.log(position) 
 
        props.isLoader(true)
 
        console.log("Call api change status")
 
        let apiData={}
        if(myAvatarList[position].is_active===1){
             apiData={"avatar_id":myAvatarList[position].id,"status":0}        
        }
        else{
             apiData={"avatar_id":myAvatarList[position].id,"status":1}        
        }
 
        console.log(apiData)
 
        updateAvatarStatus(apiData).then((resposnse)=>{
         console.log(resposnse)
         props.isLoader(false)
         if(resposnse.data.code==1){
             callAfterApiChangeStatus(position,true)
         }else{
             callAfterApiChangeStatus(position,false)
         }
         })    
     }
 
     const callAfterApiChangeStatus = (position,status)=>{
 
         if(status){
 
             if(myAvatarList[position].is_active===1){
                myAvatarList[position].is_active=0       
                }else{
                    myAvatarList[position].is_active=1
                 }
         }
 
         setAvatarList(myAvatarList)
 
         manageCheckStatus(position)
 
     }
 
     const manageCheckStatus = (position)=>{  
         var item=myAvatarList[position];

         console.log(position) 
   
          var data =renderToStaticMarkup(renderStatusUiText(myAvatarList[position]));
          $('#avatar').DataTable().row("#avatardId"+item.id).cell("#changeStatus"+item.id).data(data).draw(false);
  
     }

     const renderStatusUiText=(item)=>{
        return (
            item.is_active===1 ?
            <>
            <span class="badge badge-success">Active</span>
            </>
            :
            <>                                                
            <span class="badge badge-danger">Inactive</span>
            </>

        )
    }

     function deleteAvatarIdWise(id){
        console.log("Call Delete"+id)
        if(window.confirm("Are you sure you want to delete?")){
            props.isLoader(true)
            deleteAvatar({"avatar_id":id}).then((resposnse)=>{
                console.log(resposnse);
                if(resposnse.data.code==1){
                    $('#avatar').DataTable().row("#avatardId"+id).remove().draw();
                }else{
                }
                props.isLoader(false)
            });
        }
    }

    function capitalizeFirstLetter(str) {
        return str[0].toUpperCase() + str.slice(1);
      }
 

    if (myAvatarList==null) return null;
    props.isLoader(false)

    return (
    <section className="content home">
        <Heading title="Avatar"/>
        <div className="container-fluid"> 
        <div className="row clearfix">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card">
                    <div className="header">
                    <button type="button" className="btn btn-raised btn-primary m-t-15 m-b-15 waves-effect right" onClick={redirectCreateAvatar}>Create</button>
                    </div>
                    <div className="body">
                        <table id="avatar" className="table table-bordered table-striped table-hover dataTable js-exportable">
                            <thead>
                                <tr>
                                    <th>Created Date</th>
                                    <th>Id</th>
                                    <th>Avatar</th>
                                    <th>Type</th>
                                    <th>Status</th>
                                    <th>Status Action</th>
                                    <th>Action</th> 
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th>Created Date</th>
                                    <th>Id</th>
                                    <th>Avatar</th>
                                    <th>Type</th>
                                    <th>Status</th>
                                    <th>Status Action</th>
                                    <th>Action</th> 
                                 </tr>
                            </tfoot>
                            <tbody>

                            { 
                            myAvatarList && myAvatarList.map((item,index)=>{
                                    return(
                                        <tr id={"avatardId"+item.id}>
                                            <td>{item.created_at}</td>
                                            <td>{item.id}</td>
                                            <td>
                                                <div class="avatar">
                                                    <img src={item.avatar} class="rounded-circle" width="50" height="50" alt={item.avatar}/>
                                                </div>
                                            </td>
                                            <td>{capitalizeFirstLetter(item.type)}</td>
                                            <td id={"changeStatus"+item.id} className="text-center" > 
                                             {
                                                item.is_active===1 ?
                                                <>
                                                <span class="badge badge-success">Active</span>
                                                </>
                                                :
                                                <>                                                
                                                <span class="badge badge-danger">Inactive</span>
                                                </>
                                             }
                                            </td>
                                            <td>
                                            <i onClick={()=>statusChangeListner(index)} className="zmdi zmdi-refresh-alt"> </i>
                                            </td>
                                           <td className="text-center">
                                            <div className='action-btn'>                        
                                                    <Link to={`/editAvatar/${item.id}`} className="btn btn-default col-green"><i className="zmdi zmdi-edit"></i></Link>
                                                <a onClick={()=> {deleteAvatarIdWise(item.id)}} className="btn btn-default col-red"><i className="zmdi zmdi-delete"></i></a>
                                            </div>    
                                            </td>
                                        </tr>
                                        )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </section>
    );
}
